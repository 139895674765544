import myNotificationsStyles from "./myNotifications.module.css";
import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import {
  useLazyGetUserQuery,
  useUpdateUserSettingsMutation,
} from "../../services/api/user.api";
import { useAppSelector } from "../../services/hooks";
import { errorUserSelector } from "../../services/selectors/auth.selectors";
import Modal from "../modals/modal/Modal";
import UpdateUserInfoSuccessPopup from "../modals/updateUserInfoSuccessPopup/updateUserInfoSuccessPopup";
import { userSelector } from "../../services/selectors/user.selectors";
import { testData } from "../../utils/user";

const notificationsInitialState = {
  sendToEmail: true,
  sendAds: false,
  sendNotifications: true,
};
function MyNotifications() {
  const navigate = useNavigate();
  const tablet = useMediaQuery("(max-width: 1040px)");
  const mobile = useMediaQuery("(max-width: 740px)");

  const [notificationsState, setNotificationsState] = useState(
    notificationsInitialState
  );
  const [openModal, setOpenModal] = useState(false);

  const [updateUserSettings, { isError }] = useUpdateUserSettingsMutation();
  const [getUser] = useLazyGetUserQuery();

  const user = useAppSelector(userSelector);
  const updateError =
    useAppSelector(errorUserSelector) ?? "משהו השתבש, אנא נסה שוב מאוחר יותר";

  const submitChanges = async (
    event: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    toggleState(event);
    const response = await updateUserSettings({
      body: notificationsState,
      id: user.id,
    });
    if ("data" in response && response.data) {
      setOpenModal(true);
      getUser();
    } else {
      // setNotificationsState(notificationsInitialState);
      setOpenModal(true);
    }
  };

  const toggleState = (event: ChangeEvent<HTMLInputElement>) => {
    setNotificationsState({
      ...notificationsState,
      [event.target.name]:
        !notificationsState[
          event.target.name as keyof typeof notificationsState
        ],
    });
  };
  return (
    <section className={myNotificationsStyles.myNotifications}>
      <h2 className={myNotificationsStyles.myNotifications__title}>
        עדכונים והתראות
      </h2>
      {isError && <p className={"error"}>{updateError}</p>}
      <ul className={myNotificationsStyles.myNotifications__list}>
        <li className={myNotificationsStyles.myNotifications__variant}>
          <label
            htmlFor="sendToEmail"
            className={myNotificationsStyles.myNotifications__label}
          >
            <p className={myNotificationsStyles.myNotifications__name}>
              שליחת חשבוניות לאימייל
            </p>
            <p className={myNotificationsStyles.myNotifications__note}>
              ישלחו אל {testData.email}
            </p>
            {(!tablet || mobile) && (
              <button
                className={myNotificationsStyles.myNotifications__btn_change}
                type={"button"}
                onClick={() =>
                  navigate("/profile", {
                    replace: true,
                    state: { pathname: "profile" },
                  })
                }
              >
                שינוי
              </button>
            )}
          </label>
          <input
            type="checkbox"
            name="sendToEmail"
            id="sendToEmail"
            defaultChecked={notificationsState.sendToEmail}
            onChange={submitChanges}
            className={`${myNotificationsStyles.myNotifications__toggle} ${
              notificationsState.sendToEmail
                ? myNotificationsStyles.myNotifications__toggle_active
                : myNotificationsStyles.myNotifications__toggle_disabled
            }`}
          />
          {tablet && !mobile && (
            <button
              className={myNotificationsStyles.myNotifications__btn_change}
              type={"button"}
              onClick={() =>
                navigate("/profile", {
                  replace: true,
                  state: { pathname: "profile" },
                })
              }
            >
              שינוי
            </button>
          )}
        </li>
        <li className={myNotificationsStyles.myNotifications__variant}>
          <label
            htmlFor="sendAds"
            className={myNotificationsStyles.myNotifications__label}
          >
            <p className={myNotificationsStyles.myNotifications__name}>
              קבלת עדכונים על מבצעים והטבות
            </p>
            <p className={myNotificationsStyles.myNotifications__note}>
              ישלחו אל {testData.email}
            </p>
            {(!tablet || mobile) && (
              <button
                className={myNotificationsStyles.myNotifications__btn_change}
                type={"button"}
                onClick={() =>
                  navigate("/profile", {
                    replace: true,
                    state: { pathname: "profile" },
                  })
                }
              >
                שינוי
              </button>
            )}
          </label>
          <input
            type="checkbox"
            name="sendAds"
            id="sendAds"
            defaultChecked={notificationsState.sendAds}
            onChange={submitChanges}
            className={`${myNotificationsStyles.myNotifications__toggle} ${
              notificationsState.sendAds
                ? myNotificationsStyles.myNotifications__toggle_active
                : myNotificationsStyles.myNotifications__toggle_disabled
            }`}
          />
          {tablet && !mobile && (
            <button
              className={myNotificationsStyles.myNotifications__btn_change}
              type={"button"}
              onClick={() =>
                navigate("/profile", {
                  replace: true,
                  state: { pathname: "profile" },
                })
              }
            >
              שינוי
            </button>
          )}
        </li>
        <li className={myNotificationsStyles.myNotifications__variant}>
          <label
            htmlFor="sendNotifications"
            className={myNotificationsStyles.myNotifications__label}
          >
            <p className={myNotificationsStyles.myNotifications__name}>
              קבלת התראות ועדכונים
            </p>
            <p className={myNotificationsStyles.myNotifications__note}>
              יתקבלו למכשיר זה
            </p>
          </label>
          <input
            type="checkbox"
            name="sendNotifications"
            id="sendNotifications"
            defaultChecked={notificationsState.sendNotifications}
            onChange={submitChanges}
            className={`${myNotificationsStyles.myNotifications__toggle} ${
              notificationsState.sendNotifications
                ? myNotificationsStyles.myNotifications__toggle_active
                : myNotificationsStyles.myNotifications__toggle_disabled
            }`}
          />
        </li>
      </ul>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <UpdateUserInfoSuccessPopup onClose={() => setOpenModal(false)} />
        </Modal>
      )}
    </section>
  );
}

export default MyNotifications;

import planIcon from "../images/treatment_plan_icon.svg";
import groupsIcon from "../images/treatment_groups_icon.svg";
import practiceIcon from "../images/treatment_practice_icon.svg";
import docsIcon from "../images/treatment_docs_icon.svg";
import calendarIcon from "../images/treatment_calendar_icon.svg";
import goalsCalendarIcon from "../images/goals_calendar.svg";
import prescriptionsIcon from "../images/treatment_prescriptions_icon.svg";
import callIcon from "../images/getHelp_call.svg";
import chatIcon from "../images/getHelp_chat.svg";
import forumIcon from "../images/getHelp_forum.svg";
import videoIcon from "../images/getHelp_video.svg";
import powerIcon from "../images/goals_power.svg";
import progressIcon from "../images/goals_progress.svg";
import reportsIcon from "../images/goals_reports.svg";
import settingsIcon from "../images/profile_settings.svg";
import accountIcon from "../images/profile_account.svg";
import cardIcon from "../images/profile_card.svg";
import invoiceIcon from "../images/profile_invoice.svg";
import { IHomePageContentsSection, SubCategories } from "../types/types";

export const treatmentSections: IHomePageContentsSection[] = [
  {
    value: SubCategories.Treatment,
    icon: planIcon,
    text: "תכנית הטיפול שלי",
    navigateTo: "/treatment",
    state: {},
    disabled: true
  },
  {
    value: SubCategories.SupportGroup,
    icon: groupsIcon,
    text: "קבוצת התמיכה שלי",
    navigateTo: "/treatment",
    state: {},
    disabled: true
  },
  {
    value: SubCategories.Practice,
    icon: practiceIcon,
    text: "התרגילים שלי",
    navigateTo: "/treatment",
    state: {},
    disabled: true
  },
  {
    value: SubCategories.Docs,
    icon: docsIcon,
    text: "מסמכים שלי",
    navigateTo: "/treatment",
    state: {},
    disabled: true
  },
  {
    value: SubCategories.Calendar,
    icon: calendarIcon,
    text: "יומן פגישות",
    navigateTo: "/treatment",
    state: {},
  },
  {
    value: SubCategories.Prescriptions,
    icon: prescriptionsIcon,
    text: "מרשמים שלי",
    navigateTo: "/treatment",
    state: {},
    disabled: true
  },
];

export const profileSections: IHomePageContentsSection[] = [
  {
    value: SubCategories.Account,
    icon: accountIcon,
    text: "פרטי חשבון",
    navigateTo: "/profile",
    state: {},
  },
  {
    value: SubCategories.Card,
    icon: cardIcon,
    text: "עדכון פרטי אשראי",
    navigateTo: "/profile",
    state: {},
    disabled: true
  },
  {
    value: SubCategories.Invoices,
    icon: invoiceIcon,
    text: "חשבוניות ותשלומים",
    navigateTo: "/profile",
    state: {},
    disabled: true

  },
  {
    value: SubCategories.Settings,
    icon: settingsIcon,
    text: "הגדרות",
    navigateTo: "/profile",
    state: {},
    disabled: true
  },
];

export const helpSections: IHomePageContentsSection[] = [
  {
    value: SubCategories.Coupon,
    icon: callIcon,
    text: "בטלפון",
    navigateTo: "/prevencher",
    state: { pathname: "chatGetHelp-phone" },
  },
  {
    value: SubCategories.Coupon,
    icon: videoIcon,
    text: "בוידיאו",
    navigateTo: "/prevencher",
    state: { pathname: "chatGetHelp-video" },
  },
  {
    value: SubCategories.Coupon,
    icon: chatIcon,
    text: "בצ’אט",
    navigateTo: "/prevencher",
    state: { pathname: "chatGetHelp-chat" },
  },
  {
    value: SubCategories.Coupon,
    icon: forumIcon,
    text: "בפורום",
    navigateTo: "/forum",
    state: {},
    disabled: true
  },
];
export const goalsSections: IHomePageContentsSection[] = [
  {
    value: SubCategories.Achieved,
    icon: goalsCalendarIcon,
    text: "מה עשיתי החודש",
    navigateTo: "/goals",
    state: {},
  },
  {
    value: SubCategories.Progress,
    icon: progressIcon,
    text: "התקדמות המטרות שלי",
    navigateTo: "/goals",
    state: {},
  },
  {
    value: SubCategories.Power,
    icon: powerIcon,
    text: "דירוג החוסן שלי",
    navigateTo: "/goals",
    state: {},
  },
  {
    value: SubCategories.Reports,
    icon: reportsIcon,
    text: "דו”חות ההתקדמות שלי",
    navigateTo: "/goals",
    state: {},
  },
];

export const homePageContents = [
  {
    value: "treatment",
    title: "תפריט הטיפול",
    sections: treatmentSections,
  },
  {
    value: "help",
    title: "אנחנו כאן בשבילך",
    sections: helpSections,
  },
  {
    value: "goals",
    title: "תפריט ההתקדמות שלי",
    sections: goalsSections,
  },
  {
    value: "profile",
    title: "תפריט פרופיל",
    sections: profileSections,
  },
];

import myCalendarStyles from "./myCalendar.module.css";
import Calendar from "react-calendar";
import React, { useState } from "react";
import { getDuration } from "../../utils/utils";
import { IAppointment } from "../../types/types";
import Modal from "../modals/modal/Modal";
import AppointmentCard from "../modals/appointmentCard/appointmentCard";
import NoAppointments from "../noAppointments/noAppointments";
import { useAppSelector } from "../../services/hooks";
import {  userAppointmentsSelector } from "../../services/selectors/user.selectors";

function MyCalendar() {
  const userAppointments = useAppSelector(userAppointmentsSelector)?.filter(a => a.appointments.statusId !== 0);

  const [activeDate, setActiveDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [appointmentData, setAppointmentData] = useState<IAppointment | null>(
    null
  );

 
  return (
    <section className={myCalendarStyles.myCalendar}>
      {openModal && appointmentData && (
        <Modal onClose={() => setOpenModal(false)}>
          <AppointmentCard
            onClose={() => setOpenModal(false)}
            appointmentData={appointmentData}
          />
        </Modal>
      )}
      <h2 className={myCalendarStyles.myCalendar__title}>הפגישות שלי</h2>
      {userAppointments?.length ? (
        <div className={myCalendarStyles.calendarSection}>
          <Calendar
            calendarType={"hebrew"}
            locale={"he"}
            onClickDay={setActiveDate}
            value={activeDate}
            prev2Label={null}
            next2Label={null}
            tileContent={({ date }) => {
              const appointments: IAppointment[] = userAppointments.filter(
                (ap) => new Date(ap.appointments.appointmentDate).toDateString() === date.toDateString()
              ).slice(0, 3);
              if (appointments) {
                return (
                  <div className={myCalendarStyles.circleContainer}>
                    {appointments.map((a, index) => (
                      <span
                        key={index}
                        className={myCalendarStyles.circle}
                        style={{ background: `#5D3CC7` }}
                      />
                    ))}
                  </div>
                );
              } else {
                return null;
              }
            }}
            className={myCalendarStyles.myCalendar}
          />
          <div className={myCalendarStyles.appointmentsContainer}>
            {userAppointments?.length ? (
              <ul className={myCalendarStyles.appointmentsList}>
                {userAppointments
                  .filter(
                    (app) =>
                      new Date(app.appointments.appointmentDate).toDateString() === activeDate.toDateString()
                  )
                  .map((a, index) => (
                    <li
                      key={index}
                      className={myCalendarStyles.appointmentsLi}
                      onClick={() => {
                        setAppointmentData(a);
                        setOpenModal(true);
                      }}
                    >
                      <div className={myCalendarStyles.time}>
                        <span
                          className={`${myCalendarStyles.circle} ${myCalendarStyles.circle_big}`}
                          style={{ background: `#5D3CC7` }}
                        />
                        <p className={myCalendarStyles.duration}>
                          {getDuration(new Date(a.appointments.appointmentDate), a.appointments.startTime ?? "00:00", a.appointments?.endTime ?? "00:50")}
                        </p>
                      </div>
                      <p className={myCalendarStyles.name}>
                        {a.therapistName}
                      </p>
                    </li>
                  ))}
              </ul>
            ) : null}
          </div>
        </div>
      ) : (
        <NoAppointments />
      )}
    </section>
  );
}

export default MyCalendar;

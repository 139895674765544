import { createSlice } from "@reduxjs/toolkit";
import { TRequestsState } from "../types/requests.types";
import { handleError } from "../../utils/handleError";
import { requestsApi } from "../api/requests.api";

const initialState: TRequestsState = {
  requestNumber: "",
  error: null,
};

export const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        requestsApi.endpoints.sendCoupon.matchFulfilled,
        (state, { payload }) => {
          state.requestNumber = payload.success ? payload.number : "";
        }
      )
      .addMatcher(
        requestsApi.endpoints.sendCoupon.matchRejected,
        (state, { payload }) => {
          state.error = handleError(payload);
        }
      );
  },
});

export default requestsSlice.reducer;

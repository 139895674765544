import textSliderStyles from "./slider.module.css";
import {
  Dispatch,
  SetStateAction,
  TouchEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import myDayStyles from "../myDay/myDay.module.css";
import { IAppointment, IUpdate, IVideo } from "../../types/types";
import Video from "../video/video";
import { formatAppointmentDate } from "../../utils/utils";
import Modal from "../modals/modal/Modal";
import AppointmentCard from "../modals/appointmentCard/appointmentCard";

function Slider({
  items,
  className,
  activeIndex,
  setActiveIndex,
}: {
  items: string[] | IUpdate[] | IVideo[] | IAppointment[];
  className: string;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
}) {
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [currentAppointment, setCurrentAppointment] = useState<IAppointment | null>(null)
  const handlePrev = useCallback(() => {
    setActiveIndex((prevIndex: number) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length]);

  const handleNext = useCallback(() => {
    setActiveIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items.length]);

  const handleTouchStart = (event: TouchEvent) => {
    if (touchStartX) return;
    setTouchStartX(event.touches[0].clientX);
  };
  const handleTouchEnd = (event: TouchEvent) => {
    if (touchStartX === null) return;
    const currentX = event.changedTouches[0]?.clientX;
    const diffX = touchStartX - currentX;

    if (diffX > 50) {
      handlePrev();
    } else if (diffX < -50) {
      handleNext();
    }
    setTouchStartX(null);
  };
  useEffect(() => {
    console.log("openmodal: :", openModal);

  }, [openModal])

  return (
    <div className={textSliderStyles.carouselWrapper}>
      {(openModal && currentAppointment) && (
        <Modal onClose={() => setOpenModal(false)}>
          <AppointmentCard
            onClose={() => setOpenModal(false)}
            appointmentData={currentAppointment}
          />
        </Modal>
      )}
      <div className={textSliderStyles.carouselItems}>
        {items.map((item, index: number) => {
          if (typeof item === "string") {
            return (
              <div
                key={index}
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => handleTouchEnd(e)}
                className={`${textSliderStyles.carouselItem} `}
                style={{
                  transform: `translateX(${activeIndex * 100}%)`,
                }}
              >
                <p className={className}>{item}</p>
              </div>
            );
          }
          if ("desc" in item) {
            return (
              <div
                key={index}
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => handleTouchEnd(e)}
                className={`${textSliderStyles.carouselItem} `}
                style={{
                  transform: `translateX(${activeIndex * 100}%)`,
                }}
              >
                <div className={myDayStyles.updatesContainer}>
                  <p className={className}>{item.title}</p>
                  <p className={`${className} ${myDayStyles.desc}`}>
                    {item.desc}
                  </p>
                </div>
              </div>
            );
          } else if ("title" in item) {
            return (
              <li
                key={index}
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => handleTouchEnd(e)}
                style={{
                  transform: `translateX(${activeIndex * 100}%)`,
                }}
                className={`${myDayStyles.myDay__sectionCard} ${myDayStyles.myDay__sectionCard_centered}`}
              >
                <div className={myDayStyles.myDay__sectionContent}>
                  <p
                    className={`${myDayStyles.myDay__sectionText} ${myDayStyles.myDay__sectionText_centered} ${myDayStyles.myDay__sectionText_small}`}
                  >
                    {item.title}
                  </p>
                  <Video src={item.link} />
                </div>
              </li>
            );
          } else {
            return (
              <li
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => handleTouchEnd(e)}
                style={{
                  transform: `translateX(${activeIndex * 100}%)`,
                }}
                onClick={() => {
                  setCurrentAppointment(item);
                  setOpenModal(true);
                }}
                key={index}
                className={`${myDayStyles.myDay__sectionCard} ${myDayStyles.myDay__sectionCard_line} ${myDayStyles.myDay__sectionCardAppointments}`}
              >
                <div>
                  <p className={myDayStyles.myDay__sectionText}>
                    {item.appointments.medias
                      ? ` פגישת ${item.appointments.medias.name}`
                      : "פגישה"}{" "}
                    עם ד”ר {item.therapistName}
                  </p>
                  <p className={myDayStyles.myDay__sectionText}>
                    {formatAppointmentDate(item.appointments.appointmentDate)}{" "}
                  </p>
                  <p className={myDayStyles.myDay__sectionText}>
                    {item.appointments.endTime?.slice(0, 5) ?? "10:00"} -{" "}
                    {item.appointments.startTime?.slice(0, 5) ?? "09:00"}
                  </p>
                </div>
              </li>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Slider;

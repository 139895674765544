import {
  IUserResponse,
  TExercise,
  TGetUserCurrentFlowRequest,
  TGetUserCurrentFlowResponse,
  TInsertAllAnswersResponse,
  TQuestionairResponse,
} from "../types/auth.types";
import {
  TAppointmentResponse,
  TCancelAppointmentReason,
  TCancelAppointmentRequest,
  TGetQuestionByIdResponse,
  THelpAppointment,
  TInsertAnswerRequest,
  TUpdateUserRequest,
  TUpdateUserSettingsRequest,
  TUserTreatmentResponse,
} from "../types/user.types";
import { api } from "./api";
import { IResponse } from "../types";
import {
  TMakeAppointmentRequest,
  TUpdateAppointmentRequest,
  TInsertAllAnswersRequest,
} from "../types/user.types";
import { IAppointment, IUpdate, IUser } from "../../types/types";

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<IUserResponse, void>({
      query: () => ({
        url: `/user`,
        method: "GET",
      }),
    }),
    getUsers: build.query<IUserResponse, string>({
      query: () => ({
        url: `user/testusers`,
        method: "GET",
      }),
    }),
    getCurrentFlow: build.query<
      TGetUserCurrentFlowResponse,
      TGetUserCurrentFlowRequest
    >({
      query: ({ usersFlowId, userId }) => ({
        url: `/Questions/GetUserCurrentFlow/${usersFlowId}/${userId}`,
        method: "GET",
      }),
    }),
    updateUser: build.mutation<IUserResponse, TUpdateUserRequest>({
      query: ({ body, id }) => ({
        url: `user/${id}`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    updateUserSettings: build.mutation<
      IUserResponse,
      TUpdateUserSettingsRequest
    >({
      query: ({ body, id }) => ({
        url: `user/settings/${id}`,
        method: "PATCH",
        body: JSON.stringify(body),
      }),
    }),
    getUserTreatment: build.query<TUserTreatmentResponse, string>({
      query: () => ({
        url: `/users/treatment`,
        method: "GET",
      }),
    }),
    makeAppointment: build.mutation<IResponse, TMakeAppointmentRequest>({
      query: ({ date, therapistId }) => ({
        url: `/users/appointments`,
        method: "POST",
        body: { therapistId, date },
      }),
    }),
    updateAppointment: build.mutation<IResponse, TUpdateAppointmentRequest>({
      query: (body) => ({
        url: `/users/appointments`,
        method: "Patch",
        body,
      }),
    }),
    cancelAppointmentLocal: build.mutation<IResponse, TCancelAppointmentRequest>({
      query: (body) => ({
        url: `/users/appointments`,
        method: "DELETE",
        body,
      }),
    }),
    fetchDocks: build.query<IResponse, void>({
      query: () => ({
        url: `/users/docs`,
        method: "GET",
      }),
    }),
    sendFile: build.mutation<IResponse, FormData>({
      query: (body) => ({
        url: `/users/docs`,
        method: "POST",
        body,
        headers: {
          "content-type": "multipart/form-data",
        },
      }),
    }),
    fetchPrescriptions: build.query<IResponse, void>({
      query: () => ({
        url: `/users/prescriptions`,
        method: "GET",
      }),
    }),
    updatePrescription: build.mutation<IResponse, string>({
      query: (prescriptionId) => ({
        url: `/users/prescriptions`,
        method: "POST",
        body: { prescriptionId },
      }),
    }),
    getQuestionByQuestionnaireId: build.query<
      TGetQuestionByIdResponse,
      { currentQuestionnAirId: number; nextQuestionId: number; skip: boolean }
    >({
      query: ({ currentQuestionnAirId, nextQuestionId }) => ({
        url: `Questions/GetQuestionByQuestionnaireId/${currentQuestionnAirId}/${nextQuestionId}/0`,
        // url: `Questions/GetQuestionByQuestionnaireId/26/11/0`,
        method: "GET",
      }),
    }),
    getPOCQuestionById: build.query<
      TGetQuestionByIdResponse,
      { nextQuestionId: number; skip: boolean }
    >({
      query: ({ nextQuestionId }) => ({
        url: `Questions/GetQuestionByQuestionnaireId/26/${nextQuestionId}/0`,
        method: "GET",
      }),
    }),
    getNextQuestionByQuestionnaireId: build.query<
      TGetQuestionByIdResponse,
      { currentQuestionId: number; nextQuestionId: number; skip: boolean }
    >({
      query: ({ currentQuestionId, nextQuestionId }) => ({
        url: `Questions/GetQuestionByQuestionnaireId/${currentQuestionId}/${nextQuestionId}/0`,
        method: "GET",
      }),
    }),
    insertAnswerQuestion: build.mutation<IResponse, TInsertAnswerRequest>({
      query: (option) => ({
        url: `/Questions/InsertAnswerQuestion`,
        method: "POST",
        body: JSON.stringify(option),
      }),
    }),
    getUserByGuid: build.query<IUser, string>({
      query: (guid) => ({
        url: `/user/User_GetUserByGuid/${guid}`,
        method: "GET",
      }),
    }),
    getMessageByUserId: build.query<
      IUpdate[],
      { companyId: number; userId: number }
    >({
      query: ({ companyId, userId }) => ({
        url: `/myday/GetMessagesByUserId/${companyId}/${userId}`,
        method: "GET",
      }),
    }),
    GetCurrentQuestionnaireByUsersId: build.query<
      TQuestionairResponse,
      { guid: string; userId: number }
    >({
      query: ({ guid, userId }) => ({
        url: `/Questions/GetCurrentQuestionnaireByUsersId/${guid}`,
        method: "GET",
      }),
    }),
    getAppointmentsByUserId: build.query<
      IAppointment[],
      { companyId: number; guid: string }
    >({
      query: ({ companyId, guid }) => ({
        url: `/myday/GetAppointmentsByUserId/${companyId}/${guid}`,
        method: "GET",
      }),
      keepUnusedDataFor: 1,

    }),
    getExercisesByUserId: build.query<
      TExercise[],
      { companyId: number; guid: string }
    >({
      query: ({ companyId, guid }) => ({
        url: `/myday/GetExercisesByUserId/${companyId}/${guid}`,
        method: "GET",
      }),
    }),
    insertAllAnswersQuestions: build.mutation<
      TInsertAllAnswersResponse,
      {
        answers: TInsertAllAnswersRequest[];
        companyId: number;
        QuestionnaireCompanyId: number;
      }
    >({
      query: ({ answers, companyId, QuestionnaireCompanyId }) => ({
        url: `/Questions/InsertAllAnswersQuestions/${companyId}/${QuestionnaireCompanyId}/0/0`,
        method: "POST",
        body: JSON.stringify(answers),
      }),
    }),
    getQuestionnAirByGuidAndType: build.query<TQuestionairResponse, string>({
      query: (guid) => ({
        url: `/Questions/GetCurrentQuestionnaireByType/${guid}/2`,
        method: "GET",
        keepUnusedDataFor: 1,
      }),
    }),
    PostAppointments: build.mutation<
      TAppointmentResponse,
      { type: number; appointment: THelpAppointment, QuestionnaireCompaniesScoreUsersId: number | null }
    >({
      query: ({ type, appointment, QuestionnaireCompaniesScoreUsersId }) => ({
        url: `/Appointments/Appointments_PostAppointments/${type}/${QuestionnaireCompaniesScoreUsersId ?? ""}`,
        method: "POST",
        body: JSON.stringify(appointment),
      }),
    }),
    getAllCancelAppointmentsReasons: build.mutation<
    TCancelAppointmentReason[],
      { companyId: string | number, appointment: { Id: number, AnotherReason: string } }
    >({
      query: ({companyId, appointment}) => ({
        url: `/Appointments/Appointments_CancelApp/1/${companyId}`,
        method: "POST",
        body: appointment
      }),
    }),
    cancelAppointment: build.mutation<
      TAppointmentResponse,
      { companyId: number, appointment: TCancelAppointmentRequest }
    >({
      query: ({ appointment, companyId }) => ({
        url: `/Appointments/Appointments_CancelApp/2/${companyId}`,
        method: "POST",
        body: JSON.stringify(appointment),
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUsersQuery,
  useGetQuestionByQuestionnaireIdQuery,
  useLazyGetQuestionByQuestionnaireIdQuery,
  useLazyGetPOCQuestionByIdQuery,
  useGetUserTreatmentQuery,
  useLazyGetCurrentFlowQuery,
  useUpdateUserMutation,
  useUpdateUserSettingsMutation,
  useMakeAppointmentMutation,
  useUpdateAppointmentMutation,
  useCancelAppointmentLocalMutation,
  useFetchDocksQuery,
  useSendFileMutation,
  useFetchPrescriptionsQuery,
  useUpdatePrescriptionMutation,
  useGetCurrentFlowQuery,
  useInsertAnswerQuestionMutation,
  useLazyGetNextQuestionByQuestionnaireIdQuery,
  useGetUserByGuidQuery,
  useLazyGetUserByGuidQuery,
  useGetMessageByUserIdQuery,
  useGetCurrentQuestionnaireByUsersIdQuery,
  useLazyGetCurrentQuestionnaireByUsersIdQuery,
  useGetAppointmentsByUserIdQuery,
  useLazyGetAppointmentsByUserIdQuery,
  useGetExercisesByUserIdQuery,
  useInsertAllAnswersQuestionsMutation,
  useGetQuestionnAirByGuidAndTypeQuery,
  useLazyGetQuestionnAirByGuidAndTypeQuery,
  usePostAppointmentsMutation,
  useCancelAppointmentMutation,
  useGetAllCancelAppointmentsReasonsMutation
} = userApi;

import forumMessageStyles from "./forumMessage.module.css";
import { IForummessage } from "../../types/types";
import forumStyles from "../../pages/forum/forumPage.module.css";
import { getDate, getTimeFromTimestamp } from "../../utils/utils";
import { forumThreadExample } from "../../utils/constants";

function ForumMessage({ message }: { message: IForummessage }) {
  return (
    <li className={forumMessageStyles.li}>
      {message.replyTo && (
        <article className={forumMessageStyles.card_answer}>
          <p
            className={`${forumMessageStyles.card__text} ${forumMessageStyles.card__text_answer}`}
          >
            תגובה ישירה לציטוט:
          </p>
          <div className={forumStyles.card__user}>
            <img
              className={forumStyles.card__avatar}
              src={forumThreadExample.messages[1].user.avatar}
              alt={forumThreadExample.messages[1].user.userName}
            />
            <div className={forumStyles.card__data}>
              <p className={forumStyles.card__name}>
                {forumThreadExample.messages[1].user.userName}
              </p>
              <p className={forumStyles.card__date}>
                {`${getDate(
                  message.user.date.toString()
                )}, ${getTimeFromTimestamp(
                  forumThreadExample.messages[1].user.date
                )}`}
              </p>
            </div>
            {forumThreadExample.messages[1].user.tag && (
              <p className={forumMessageStyles.card__tag}>
                {forumThreadExample.messages[1].user.tag}
              </p>
            )}
          </div>
          <p className={forumMessageStyles.card__text}>
            {forumThreadExample.messages[1].message}
          </p>
        </article>
      )}
      <article className={forumMessageStyles.card}>
        <div className={forumStyles.card__user}>
          <img
            className={forumStyles.card__avatar}
            src={message.user.avatar}
            alt={message.user.userName}
          />
          <div className={forumStyles.card__data}>
            <p className={forumStyles.card__name}>{message.user.userName}</p>
            <p className={forumStyles.card__date}>
              {`${getDate(
                message.user.date.toString()
              )}, ${getTimeFromTimestamp(message.user.date)}`}
            </p>
          </div>
          {message.user.tag && (
            <p className={forumMessageStyles.card__tag}>{message.user.tag}</p>
          )}
        </div>
        <p className={forumMessageStyles.card__text}>{message.message}</p>
      </article>
    </li>
  );
}

export default ForumMessage;

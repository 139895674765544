import React, { MutableRefObject, useEffect, useRef } from "react";
import styles from "./progressBar.module.css";

interface ICircularProgressProps {
  progressValue: number;
  pathRef: MutableRefObject<SVGPathElement | null>;
}

const CircularProgress = ({
  progressValue,
  pathRef,
}: ICircularProgressProps) => {
  useEffect(() => {
    if (pathRef.current) {
      const pathLength = pathRef.current.getTotalLength();
      const normalizedProgress = Math.max(0, Math.min(0, progressValue));
      const strokeDashoffset =
        pathLength - (normalizedProgress / 1000) * pathLength;

      // Set the strokeDashoffset dynamically
      pathRef.current.style.strokeDashoffset = `${strokeDashoffset}`;
    }
  }, [progressValue]);

  return (
    <div className={styles.progress}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="204"
        height="169"
        viewBox="0 0 204 169"
        fill="none"
      >
        <path
          d="M20.4812 161C12.5145 146.318 8 129.492 8 111.617C8 54.393 54.3006 8 111.411 8C146.372 8 177.283 25.3862 196 52"
          stroke="url(#paint0_linear_1322_3248)"
          strokeWidth="16"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1322_3248"
            x1="28.9819"
            y1="161"
            x2="229.829"
            y2="111.081"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FE919E" />
            <stop offset="1" stopColor="#5D3CC7" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default CircularProgress;

import accountPageStyles from "./profilePage.module.css";
import Questionnaire from "../../components/questionnaire/questionnaire";
import MyAccount from "../../components/myAccount/myAccount";
import MyCard from "../../components/myCard/myCard";
import { ISubCategory } from "../../types/types";
import MyInvoices from "../../components/myInvoices/myInvoices";
import MySettings from "../../components/mySettings/mySettings";
import { subCategories } from "../../utils/categories";

function ProfilePage({
  activeCategory = subCategories.settings,
}: {
  activeCategory: ISubCategory;
}) {
  return (
    <main className={accountPageStyles.main}>
      <Questionnaire />
      {activeCategory === subCategories.account && <MyAccount />}
      {activeCategory === subCategories.card && <MyCard />}
      {activeCategory === subCategories.invoices && <MyInvoices />}
      {activeCategory === subCategories.settings && <MySettings />}
    </main>
  );
}

export default ProfilePage;

import loginStyles from "./login.module.css";
import Urgent from "../../components/urgent/urgent";
import { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import LoginSmsForm from "../../components/forms/loginSmsForm/loginSmsForm";
import LoginEmailForm from "../../components/forms/loginEmailForm/loginEmailForm";
import OnboardingLayout from "../../components/onboardingLayout/onboardingLayout";
import { Link, useNavigate } from "react-router-dom";
import Tooltip from "../../components/tooltip/tooltip";
import { useLoginGoogleMutation } from "../../services/api/auth.api";
import { useAppSelector } from "../../services/hooks";
import { errorUserSelector } from "../../services/selectors/auth.selectors";
import { TLoginGoogleRequest } from "../../services/types/auth.types";

function LoginPage() {
  const navigate = useNavigate();
  const [way, setWay] = useState("sms");
  const [showTooltip, setShowTooltip] = useState(false);
  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      sendTokenToServer(codeResponse as TLoginGoogleRequest)
        .then((response) => {
          if ("data" in response && response.data.success) {
            // useGetCurrentFlowQuery();
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(loginError);
        });
    },
  });
  const [sendTokenToServer, { isError }] = useLoginGoogleMutation();
  const loginError = useAppSelector(errorUserSelector);

  return (
    <OnboardingLayout>
      <article className={loginStyles.login}>
        <button
          type={"button"}
          onClick={() => navigate("/")}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
          className={loginStyles.login__arrow}
        >
          <Tooltip isShown={showTooltip}>לחזור לאונבורדינג</Tooltip>
        </button>
        <h1 className={loginStyles.login__title}>התגעגענו</h1>
        <p className={loginStyles.login__text}>התחברות לחשבונך</p>
        <div className={loginStyles.login__way}>
          <button
            type="button"
            onClick={() => setWay("email")}
            className={`${loginStyles.login__radioLabel} ${
              way === "email" && loginStyles.login__radioLabel_active
            }`}
          >
            אימייל
          </button>
          <button
            type="button"
            onClick={() => setWay("sms")}
            className={`${loginStyles.login__radioLabel} ${
              way === "sms" && loginStyles.login__radioLabel_active
            }`}
          >
            SMS
          </button>
        </div>
        {way === "sms" ? <LoginSmsForm /> : <LoginEmailForm />}
        <div className={loginStyles.login__bottomBar}>
          <p className={loginStyles.login__alternative}>או</p>
          <div className={loginStyles.login__social}>
            <p
              className={`${loginStyles.login__text} ${loginStyles.login__text_small}`}
            >
              התחבר דרך
            </p>
            <ul className={loginStyles.login__socialList}>
              <li>
                <button
                  type="button"
                  onClick={() => loginWithGoogle()}
                  className={`${loginStyles.login__socialBtn} ${loginStyles.login__socialBtn_google} hover`}
                />
              </li>
              <li>
                <button
                  type="button"
                  className={`${loginStyles.login__socialBtn} ${loginStyles.login__socialBtn_apple} hover`}
                />
              </li>
              <li>
                <button
                  type="button"
                  className={`${loginStyles.login__socialBtn} ${loginStyles.login__socialBtn_facebook} hover`}
                />
              </li>
            </ul>
          </div>
        </div>
        {isError && <p className={"error"}>{loginError}</p>}

        <div>
          <p className={loginStyles.login__conditions}>שימוש באפליקציה תלוי </p>
          <Link className={loginStyles.login__link} to={"/terms"}>
            בתקנון השימוש
          </Link>
        </div>
        <Urgent />
      </article>
    </OnboardingLayout>
  );
}

export default LoginPage;

import myAccountStyles from "./myAccount.module.css";
import avatarPlaceholder from "../../images/avatar_placeholder.svg";
import avatarPlaceholderPurple from "../../images/avatar_placeholder_purple.svg";
import React, { useState } from "react";
import Modal from "../modals/modal/Modal";
import ChangePasswordPopup from "../modals/changePasswordPopup/changePasswordPopup";
import UpdateUserInfoSuccessPopup from "../modals/updateUserInfoSuccessPopup/updateUserInfoSuccessPopup";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../services/hooks";
import { errorUserSelector } from "../../services/selectors/auth.selectors";
import ChangeAvatarPopup from "../modals/changeAvatarPopup/changeAvatarPopup";
import { useFormik } from "formik";
import { updateUserValidationSchema } from "../../utils/validation";
import { defaultAvatarLink, mobileWidth, testUserEmail, testUserEmail2 } from "../../utils/constants";
import {
  useLazyGetUserQuery,
  useUpdateUserMutation,
} from "../../services/api/user.api";
import { getChangedValues } from "../../utils/utils";
import { userSelector } from "../../services/selectors/user.selectors";

function MyAccount() {
  const navigate = useNavigate();
  const [openPasswordModal, setOpenPasswordModal] = useState(false);
  const [openUserInfoModal, setOpenUserInfoModal] = useState(false);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);

  const [updateUser, { isError }] = useUpdateUserMutation();
  const [getUser] = useLazyGetUserQuery();

  const user = useAppSelector(userSelector);
  const updateError = useAppSelector(errorUserSelector);
  const isTestUser =
    user?.email.toLowerCase() === testUserEmail ||
    user?.email.toLowerCase() === testUserEmail2;

  const mobile = useMediaQuery(mobileWidth);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: user,
    validationSchema: updateUserValidationSchema,
    onSubmit: async (values) => {
      // Handle form submission
      const changedValues = getChangedValues(values, formik.initialValues);
      const body = Object.keys(changedValues).map((key) => {
        return {
          op: "replace",
          path: `/${key}`,
          value: values[key],
        };
      });
      updateUser({ body, id: user.id }).then((response) => {
        if ("data" in response && response.data) {
          setOpenUserInfoModal(true);
          getUser();
        }
      });
    },
  });

  return (
    <div className={myAccountStyles.myAccount}>
      {openPasswordModal && (
        <Modal onClose={() => setOpenPasswordModal(false)}>
          <ChangePasswordPopup onClose={() => setOpenPasswordModal(false)} />
        </Modal>
      )}
      {openUserInfoModal && (
        <Modal onClose={() => setOpenUserInfoModal(false)}>
          <UpdateUserInfoSuccessPopup
            onClose={() => setOpenUserInfoModal(false)}
          />
        </Modal>
      )}
      {openAvatarModal && (
        <Modal onClose={() => setOpenAvatarModal(false)}>
          <ChangeAvatarPopup onClose={() => setOpenAvatarModal(false)} />
        </Modal>
      )}

      <div className={myAccountStyles.myAccount__content}>
        <h2 className={myAccountStyles.myAccount__title}>פרטי חשבון</h2>
        <img
          src={`${user.avatar
            ? user.avatar
            : defaultAvatarLink ?
              defaultAvatarLink :
              mobile
                ? avatarPlaceholderPurple
                : avatarPlaceholder
            }`}
          alt="תמונת משתמש"
          className={myAccountStyles.myAccount__avatarPlaceholder}
        />
        <button
          type="button"
          className={`${myAccountStyles.myAccount__addAvatar} ${mobile && myAccountStyles.myAccount__addAvatar_mobile
            }`}
          onClick={() => setOpenAvatarModal(true)}
        />
        {user.account && (
          <p className={myAccountStyles.myAccount__accountNumber}>
            מספר משתמש {user.account}
          </p>
        )}
      </div>
      <form
        id={"updateUser"}
        onSubmit={formik.handleSubmit}
        className={myAccountStyles.myAccount__form}
      >
        <ul className={myAccountStyles.myAccount__list}>
          <li>
            <label
              htmlFor="firstName"
              className={myAccountStyles.myAccount__label}
            >
              שם פרטי
            </label>
            <input
              type="text"
              className={`${myAccountStyles.myAccount__input} ${formik.errors.firstName &&
                myAccountStyles.myAccount__input_invalid
                }`}
              name="firstName"
              id="firstName"
              value={formik.values.firstName ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </li>
          <li>
            <label
              htmlFor="lastName"
              className={myAccountStyles.myAccount__label}
            >
              שם משפחה
            </label>
            <input
              type="text"
              className={`${myAccountStyles.myAccount__input} ${formik.errors.lastName &&
                myAccountStyles.myAccount__input_invalid
                }`}
              name="lastName"
              id="lastName"
              value={formik.values.lastName ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </li>
          <li>
            <label htmlFor="email" className={myAccountStyles.myAccount__label}>
              אימייל
            </label>
            <input
              type="email"
              className={`${myAccountStyles.myAccount__input} ${formik.errors.email && myAccountStyles.myAccount__input_invalid
                }`}
              name="email"
              id="email"
              value={formik.values.email ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </li>
          <li>
            <label htmlFor="phone" className={myAccountStyles.myAccount__label}>
              נייד
            </label>
            <input
              type="string"
              className={`${myAccountStyles.myAccount__input} ${formik.errors.phone && myAccountStyles.myAccount__input_invalid
                }`}
              name="phone"
              id="phone"
              value={
                formik.values.phone
                  ? formik.values.phone.replace(/[^\d]+/g, "")
                  : ""
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder={formik.values.phone}
            />
          </li>
          <li>
            <label
              htmlFor="address"
              className={myAccountStyles.myAccount__label}
            >
              כתובת מגורים
            </label>
            <p className={myAccountStyles.myAccount__note}>
              אנו צריכים את כתובתך כדי לחבר אותך לקהילה המקומית
            </p>
            <input
              type="text"
              className={`${myAccountStyles.myAccount__input} ${formik.errors.address &&
                myAccountStyles.myAccount__input_invalid
                }`}
              name="address"
              id="address"
              value={formik.values.address ?? ""}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </li>
          <li>
            <div className={myAccountStyles.myAccount__info}>
              <span className={`${myAccountStyles.myAccount__label}`}>סיסמה</span>
              <button
                disabled={!isTestUser}
                type="button"
                onClick={() => {
                  setOpenPasswordModal(true);
                }}
                className={`${myAccountStyles.myAccount__changeBtn} ${!isTestUser && myAccountStyles.myAccount__changeBtn_disabled}`}
              >
                {mobile ? "" : "עריכת סיסמה"}
              </button>
            </div>
            <p
              className={`${myAccountStyles.myAccount__input} ${myAccountStyles.myAccount__password}`}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8].map((circle, index) => (
                <span
                  key={index}
                  className={myAccountStyles.myAccount__circle}
                />
              ))}
            </p>
          </li>
          <li>
            <div className={myAccountStyles.myAccount__info}>
              <span className={myAccountStyles.myAccount__label}>
                פרטי אשראי
              </span>
              <button
                disabled={!isTestUser}
                type="button"
                onClick={() => {
                  navigate("/profile", {
                    replace: true,
                    state: { pathname: "updateCard" },
                  });
                }}
                className={`${myAccountStyles.myAccount__changeBtn} ${!isTestUser && myAccountStyles.myAccount__changeBtn_disabled}`}
              >
                {mobile ? "" : "עריכת פרטי אשראי"}
              </button>
            </div>
            <p
              className={`${myAccountStyles.myAccount__input} ${myAccountStyles.myAccount__account
                } ${!user.card && myAccountStyles.myAccount__account_placeholder
                }`}
            >
              {user.card ? `xxxx xxxx xxxx ${user.card}` : "לא הוגדר"}
            </p>
          </li>
        </ul>
      </form>
      {formik.errors && (
        <p className={`${myAccountStyles.myAccount__invalid_message}`}>
          {formik.errors.firstName ??
            formik.errors.lastName ??
            formik.errors.email ??
            formik.errors.phone ??
            formik.errors.address}
        </p>
      )}
      {isError && (
        <p className={`${myAccountStyles.myAccount__invalid_message}`}>
          {updateError}
        </p>
      )}
      <button
        type={"submit"}
        form={"updateUser"}
        disabled={!formik.isValid || formik.isSubmitting || !formik.dirty}
        className={`button ${!mobile && "button_white"} hover ${myAccountStyles.btn
          } ${(!formik.isValid || formik.isSubmitting || !formik.dirty) &&
          myAccountStyles.btn_disabled
          }`}
      >
        עריכת פרטי חשבון
      </button>
    </div>
  );
}

export default MyAccount;

export function getRandomDatesWithTimesOfCurrentMonth(): Date[] {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const daysInCurrentMonth = new Date(
    currentYear,
    currentMonth + 1,
    0
  ).getDate();

  const randomDatesWithTimes: Date[] = [];

  for (let i = 0; i < 10; i++) {
    const randomDay = Math.floor(Math.random() * daysInCurrentMonth) + 1;
    const randomHour = Math.floor(Math.random() * 24);
    const randomMinute = Math.floor(Math.random() * 60);
    const randomSecond = Math.floor(Math.random() * 60);

    const randomDate = new Date(
      currentYear,
      currentMonth,
      randomDay,
      randomHour,
      randomMinute,
      randomSecond
    );
    randomDatesWithTimes.push(randomDate);
  }

  return randomDatesWithTimes;
}

import popupStyles from "./chat_popup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";
import CloseButton from "../../closeButton/closeButton";

interface ISupportSuccessPopup extends IPopupWithoutProps {
  text: string;
  subText?: string;
}
function ChatPopup({ onClose, text, subText }: ISupportSuccessPopup) {
  return (
    <article className={popupStyles.popup}>
      <CloseButton onClick={onClose} />
      <h2 className={popupStyles.popup__title}>{text}</h2>
      {subText && <p className={popupStyles.popup__text}>{subText} </p>}
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={popupStyles.popup__img}
      />
      <button
        className={`button ${popupStyles.popup__btn} hover`}
        onClick={() => {
          onClose();
        }}
      >
        אישור
      </button>
    </article>
  );
}

export default ChatPopup;

import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import onboardingStyles from "./onboarding.module.css";
import { useEffect, useState } from "react";
import onboardingImages from "../../utils/onboardingImages";
import Urgent from "../../components/urgent/urgent";

function OnboardingPage() {
  const [index, setIndex] = useState<number>(0);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = window.setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        if (index + 1 > onboardingImages.length - 1) {
          setIndex(0);
        } else setIndex(index + 1);
        setFadeOut(false);
      }, 800);
    }, 3000);

    return () => window.clearInterval(timer);
  }, [index]);
  return (
    <main className={onboardingStyles.main}>
      <article className={onboardingStyles.welcome}>
        <img className={onboardingStyles.welcome__logo} src={logo} alt="logo" />
        <img
          className={`${onboardingStyles.welcome__pix} ${
            fadeOut ? onboardingStyles.fade : onboardingStyles.fadein
          }`}
          src={onboardingImages[index]}
          alt="ליווי של מדריך אישי אנושי ומקצועי"
        />
        <h2 className={onboardingStyles.welcome__title}>
          ליווי של מדריך אישי אנושי ומקצועי
        </h2>
        <p className={onboardingStyles.welcome__text}>
          צור סיסמה באורך של לפחות 6 תווים, שילוב של אותיות, מספרים וסמלים
        </p>
        <ul className={`${onboardingStyles.welcome__pagination}`}>
          {onboardingImages.map((i, ind) => (
            <li
              key={ind}
              onClick={() => setIndex(ind)}
              className={`${onboardingStyles.welcome__page} ${
                index === ind && onboardingStyles.welcome__page_active
              }`}
            />
          ))}
        </ul>
        <div className={onboardingStyles.welcome__buttonBar}>
          <Link
            to="/login"
            className={`${onboardingStyles.welcome__btn} ${onboardingStyles.welcome__btn_type_login} hover`}
          >
            כניסה
          </Link>
          <Link
            to="/signin"
            className={`${onboardingStyles.welcome__btn} ${onboardingStyles.welcome__btn_type_signin} hover`}
          >
            הרשמה
          </Link>
        </div>
        <Urgent />
        <div className={onboardingStyles.welcome__forTherapistsNote}>
          <p className={onboardingStyles.welcome__forTherapists}>רופא? </p>
          <Link
            className={onboardingStyles.welcome__forTherapists_link}
            to={"http://test-therapist.bpreven.com/"}
          >
            היכנס
          </Link>
          <p className={onboardingStyles.welcome__forTherapists}>או </p>
          <Link
            className={onboardingStyles.welcome__forTherapists_link}
            to={"http://test-therapist.bpreven.com/"}
          >
            הירשם
          </Link>
          <p className={onboardingStyles.welcome__forTherapists}> כרופא</p>
        </div>
      </article>
    </main>
  );
}

export default OnboardingPage;

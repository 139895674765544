export const disorders = [
  "דיכאון",
  "התפתחות",
  "התמכרות",
  "הפרעות חרדה",
  "חרדה כללית",
  "חרדה חברתית",
  "התקפי חרדה",
  "סכיזופרניה",
  "הפרעת אכילה",
  "נדודי שינה",
  "פאניקה",
  "אגורפוביה",
  "מצב רוח",
  "תסמונת קדם-וסתית",
  "הפרעות סומטופורמיות",
  "היפוכונדריה",
  "הפרעות אישיות",
  "הפרעות הסתגלות",
  "התנהגות",
];

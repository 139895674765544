import changePasswordPopupStyles from "./changeAvatarPopup.module.css";
import React, { useState } from "react";
import { IPopupWithoutProps } from "../../../types/types";
import ChangePasswordSuccessPopup from "../changePasswordSuccessPopup/changePasswordSuccessPopup";
import { useFormik } from "formik";
import { changeAvatarValidationSchema } from "../../../utils/validation";
import loginStyles from "../../../pages/login/login.module.css";
import {
  useLazyGetUserQuery,
  useUpdateUserMutation,
} from "../../../services/api/user.api";
import { useAppSelector } from "../../../services/hooks";
import { errorUserSelector } from "../../../services/selectors/auth.selectors";
import { userSelector } from "../../../services/selectors/user.selectors";
import CloseButton from "../../closeButton/closeButton";

function ChangeAvatarPopup({ onClose }: IPopupWithoutProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [updateUser, { isError }] = useUpdateUserMutation();
  const [getUser] = useLazyGetUserQuery();
  const updateError = useAppSelector(errorUserSelector);
  const user = useAppSelector(userSelector);

  const handleSubmit = async (avatar: string) => {
    const response = await updateUser({
      body: [
        {
          op: "replace",
          path: `/avatar`,
          value: avatar,
        },
      ],
      id: user.id,
    });
    if ("data" in response && response.data) {
      setOpenModal(true);
      getUser();
    }
  };

  const handleImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formik.setFieldValue("avatar", reader.result); // Save base64 string in Formik
        formik.setFieldValue("avatarName", file); // Save base64 string in Formik
      };
      reader.readAsDataURL(file);
    }
  };

  const formik = useFormik({
    initialValues: {
      avatar: "",
      avatarName: ""
    },
    validationSchema: changeAvatarValidationSchema,
    onSubmit: (values) => {
      // Handle form submission
      handleSubmit(values.avatar);
    },
  });

  return openModal ? (
    <ChangePasswordSuccessPopup onClose={onClose} type={"avatar"} />
  ) : (
    <article className={changePasswordPopupStyles.popup}>
      <div className={changePasswordPopupStyles.popup__content}>
        <CloseButton onClick={onClose} />
        <h2 className={changePasswordPopupStyles.popup__title}>
          שינוי תמונת פרופיל
        </h2>
        <form
          id="updateAvatar"
          onSubmit={formik.handleSubmit}
          className={changePasswordPopupStyles.popup__form}
        >
          {formik.values.avatar && (
            <img
              src={formik.values.avatar}
              alt="Avatar Preview"
              className="w-full h-full object-cover rounded-full border border-gray-300"
              style={{width: "100px", height: " 100px"}}
            />
          )}
          <label
            htmlFor={"avatar"}
            className={changePasswordPopupStyles.popup__label}
          >
            העלה תמונת פרופיל
          </label>
          <input
            type="file"
            name={"avatar"}
            id={"avatar"}
            placeholder={"הזן קישור"}
            // value={formik.values.avatar}
            accept="image/*"
            onChange={(e) => handleImageChange(e)}
            onBlur={formik.handleBlur}
            className={`${changePasswordPopupStyles.popup__input} ${formik.errors.avatar &&
              changePasswordPopupStyles.popup__input_invalid
              }`}
          />
          {formik.errors.avatar && (
            <p className={`${loginStyles.login__invalid_message}`}>
              {formik.errors.avatar}
            </p>
          )}
          {isError && (
            <p className={`${loginStyles.login__invalid_message}`}>
              {updateError}
            </p>
          )}
          <button
            type="submit"
            form="updateAvatar"
            disabled={!formik.isValid || formik.isSubmitting}
            className={`button hover ${changePasswordPopupStyles.popup__submitBtn
              } ${(!formik.isValid || formik.isSubmitting) &&
              changePasswordPopupStyles.popup__submitBtn_disabled
              }`}
          >
            לשינוי התמונה
          </button>
        </form>
      </div>
    </article>
  );
}

export default ChangeAvatarPopup;

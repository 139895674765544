import styles from "./myProgress.module.css";
import BackArrowButton from "../backArrowButton/backArrowButton";
import { useNavigate } from "react-router-dom";
import goalsImg from "../../images/achievements.png";
import venchyImg from "../../images/questionnaire_doctor.png";
import { useAppSelector } from "../../services/hooks";
import { userGoalsSelector } from "../../services/selectors/goals.selectors";

function MyProgress() {
  const navigate = useNavigate();
  const userGoals = useAppSelector(userGoalsSelector);

  return (
    <section className={styles.section}>
      <h2 className={styles.section__title}>המטרות שלי</h2>
      <BackArrowButton position={"center"} onClick={() => navigate(-1)} />
      {userGoals.length > 0 ? (
        <article className={styles.goals}>
          <ul className={styles.goals__list}>
            {userGoals.map((g, index) => (
              <li key={index} className={styles.goals__li}>
                <div className={styles.goals__header}>
                  <h4 className={styles.goals__title}>{g.title}</h4>
                  <button className={styles.goals__editBtn} />
                </div>
                <p
                  className={styles.goals__progressNumber}
                  style={{ marginRight: `${g.progress - 7}%` }}
                >
                  {g.progress}%
                </p>
                <div className={styles.goals__progressBar}>
                  <div
                    className={styles.goals__progress}
                    style={{ width: `${g.progress}%` }}
                  />
                </div>
                <div className={styles.goals__comments}>
                  <img
                    src={venchyImg}
                    alt={""}
                    className={styles.goals__avatar}
                  />
                  <p className={styles.goals__text}>
                    <span
                      className={`${styles.goals__text} ${styles.goals__text_bold}`}
                    >
                      {g.comment.split(" ")[0]}
                    </span>
                    {g.comment.split("! ")[1]}
                  </p>
                </div>
              </li>
            ))}
          </ul>
          <button
            className={`button ${styles.noGoals__btn} hover`}
            onClick={() =>
              navigate("/prevencher", { state: { pathname: "chatSetGoals" } })
            }
          >
            קביעת מטרות נוספות
          </button>
        </article>
      ) : (
        <article className={styles.noGoals}>
          <img
            src={goalsImg}
            alt={"גיבור על טס לעבר המטרה שלו"}
            className={styles.noGoals__img}
          />
          <p className={styles.noGoals__text}>לא נראה שהגדרת מטרות</p>
          <button
            className={`button ${styles.noGoals__btn} hover`}
            onClick={() =>
              navigate("/prevencher", { state: { pathname: "chatSetGoals" } })
            }
          >
            קביעת מטרות
          </button>
        </article>
      )}
    </section>
  );
}
export default MyProgress;

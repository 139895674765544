import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/authUser.slice";
import therapistsReducer from "../slices/therapists.slice";
import requestsReducer from "../slices/requests.slice";
import goalsReducer from "../slices/goals.slice";
import utilsReducer from "../slices/utils.slice";
import chatReducer from "../slices/chat.slice";
import { authApi } from "../api/auth.api";
import { chatApi } from "../api/chat.api";

export const rootReducer = combineReducers({
  user: userReducer,
  therapists: therapistsReducer,
  requests: requestsReducer,
  goals: goalsReducer,
  utils: utilsReducer,
  chat: chatReducer,
  [authApi.reducerPath]: authApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
});

export const index = configureStore({
  reducer: rootReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      authApi.middleware,
      chatApi.middleware,
    ]),
  devTools: process.env.NODE_ENV === "development",
  preloadedState: undefined,
});

export default store;

import popupStyles from "./scannedDocument_success_popup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";
import CloseButton from "../../closeButton/closeButton";

function ScannedDocumentPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={popupStyles.popup}>
      <CloseButton onClick={onClose} />
      <h2 className={popupStyles.popup__title}>מסמך עלה בהצלחה!</h2>
      <p className={popupStyles.popup__text}>
        אנו נעדכן אותך עם סיום טיפול במסמך.
      </p>
      <img
        src={doctor}
        alt="Docktor picture"
        className={popupStyles.popup__img}
      />
      <button
        type="button"
        className={popupStyles.popup__nextBtn}
        onClick={onClose}
      >
        אישור
      </button>
    </article>
  );
}

export default ScannedDocumentPopup;

import prevencherChatStyles from "./prevencherChat.module.css";
import { venchyData } from "../../utils/constants";
import DOMPurify from "dompurify";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import Message from "../message/message";
import { useAppSelector } from "../../services/hooks";
import {
  currentQuestionSelector,
  currentQuestionIdSelector,
  endQuestionSelector,
  nextQuestionIdSelector,
  prevencherChatMessagesSelector,
  questionnaireIdSelector,
  questionsOptionsSelector,
  userSelector,
  allAnswersSelector,
  currentQuestionnaireCompanyIdSelector,
} from "../../services/selectors/user.selectors";
import {
  useGetQuestionnAirByGuidAndTypeQuery,
  useInsertAllAnswersQuestionsMutation,
  useLazyGetNextQuestionByQuestionnaireIdQuery,
  useLazyGetQuestionByQuestionnaireIdQuery,
  usePostAppointmentsMutation,
} from "../../services/api/user.api";
import Loader from "../loader/loader";
import {
  IQuestionOption,
  THelpAppointment,
} from "../../services/types/user.types";
import { useActions } from "../../services/hooks/useActions";
import { prevencherId } from "../../utils/prevencherForGuestsQuestions";
import {
  formatDateTimeInIsrael,
  israeliPhoneRegExp,
  replaceVariables,
} from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Category } from "../../types/types";

interface IPrevencherChatUrgentProps {
  activeCategory: Category;
  changeCategory: (e: React.MouseEvent, cat: Category) => void;
}
function PrevencherChatUrgent({ changeCategory }: IPrevencherChatUrgentProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const lastElRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation(); // the way to reach the getHelp method from the user choice

  const user = useAppSelector(userSelector);
  useGetQuestionnAirByGuidAndTypeQuery(user.guid);

  const allAnswers = useAppSelector(allAnswersSelector);
  const nextQuestionId = useAppSelector(nextQuestionIdSelector);
  const questionnaireId = useAppSelector(questionnaireIdSelector);
  const currentQuestionnaireCompanyId = useAppSelector(
    currentQuestionnaireCompanyIdSelector
  );
  const currentQuestion = useAppSelector(currentQuestionSelector);
  const currentQuestionId = useAppSelector(currentQuestionIdSelector);
  const questionsOptions = useAppSelector(questionsOptionsSelector);
  const endQuestion = useAppSelector(endQuestionSelector);
  const messages = useAppSelector(prevencherChatMessagesSelector);

  const [isDataLoaded, setIsDataLoaded] = useState(questionnaireId >= 0);
  const [options, setOptions] = useState<IQuestionOption[]>([]);
  const [showInput, setShowInput] = useState(false);
  const [showTextArea, setShowTextArea] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isEnd, setIsEnd] = useState<boolean>(false);
  const [startChat, setStartChat] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("ונצ'י מקליד");
  const [QuestionnaireCompaniesScoreUsersId, setQuestionnaireCompaniesScoreUsersId] = useState<number | null>(null)
  const {
    setMessages,
    setAnswers,
    setNextQuestion,
    resetQuestionnair,
    setCurrentQuestion,
  } = useActions();

  const [getQuestionById, getQuestionByIdResult] =
    useLazyGetQuestionByQuestionnaireIdQuery();
  const [getNextQuestionById] = useLazyGetNextQuestionByQuestionnaireIdQuery();
  const [insertAllAnswers] = useInsertAllAnswersQuestionsMutation();
  const [postAppointment] = usePostAppointmentsMutation();

  useEffect(() => {
    resetQuestionnair();
  }, [resetQuestionnair]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, options]);

  useEffect(() => {
    //@ts-ignore
    if (nextQuestionId === null || nextQuestionId === "null") {
      setNextQuestion();
    }
  }, [nextQuestionId, setNextQuestion]);

  useEffect(() => {
    if (nextQuestionId >= 0 && questionnaireId >= 0) {
      setIsDataLoaded(true);
    }
  }, [nextQuestionId, questionnaireId]);

  useEffect(() => {
    if (isDataLoaded) {
      if (questionnaireId >= 0 && !messages.length) {
        getQuestionById({
          currentQuestionnAirId: questionnaireId,
          nextQuestionId: nextQuestionId ? nextQuestionId : currentQuestionId,
          skip: !isDataLoaded,
        });
      }
      setIsDataLoaded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  useEffect(() => {
    const message = currentQuestion?.Desc
      ? replaceVariables(currentQuestion?.Desc, user)
      : replaceVariables(endQuestion, user);
    if (
      getQuestionByIdResult.status === "fulfilled" &&
      currentQuestion?.Desc &&
      messages.at(-1)?.message !== message
    ) {
      setMessages({
        id: prevencherId,
        date: new Date(),
        message: message,
        unread: false,
        nextQuestionId: 0,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getQuestionByIdResult.status]);

  useEffect(() => {
    if (currentQuestion?.DataTypesId === 1) setOptions(questionsOptions);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsOptions]);

  useEffect(() => {
    if (currentQuestion?.DataTypesId === 2) {
      currentQuestion?.Id === 72 || currentQuestion?.Id === 19
        ? setShowTextArea(true)
        : setShowInput(true);
    } else {
      setShowTextArea(false);
      setShowInput(false);
    }
    if (currentQuestion?.DataTypesId === 5) {
      setIsLoading(true);
      setOptions([]);
      delay(1500).then(() => {
        if (currentQuestion.NextQuestionId) {
          getNextQuestionById({
            currentQuestionId: currentQuestionId,
            nextQuestionId: currentQuestion.NextQuestionId,
            skip: false,
          });
        } else {
          setIsLoading(false);
          setIsEnd(true);
        }
      });
    } else {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion, getNextQuestionById, setOptions]);

  useEffect(() => {
    if (isEnd) {
      insertAllAnswers({
        answers: allAnswers,
        companyId: 1,
        QuestionnaireCompanyId: currentQuestionnaireCompanyId ?? 0,
      }).then((res) => {
        if ("data" in res) {
          setQuestionnaireCompaniesScoreUsersId(res.data.QuestionnaireCompaniesScoreUsersId)
        }
      });
      setIsEnd(false);
      setStartChat(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnd]);

  useEffect(() => {
    if (startChat) {
      const question = {
        DataTypes: { Id: 1, Desc: "OptionId" },
        DataTypesId: 1,
        Desc: "המערכת כרגע מאתרת נציג שישוחח איתך...",
        FactorQuestion: 1,
        Id: "start chat",
        IsNoScore: false,
        IsSpecific: null,
        NextQuestionId: null,
        OptionsCountMax: 1,
        OptionsCountMin: 0,
        Seq: 3,
        SpecificText: null,
        StatusId: 1,
      };
      const questionOptions = [
        {
          Id: 1111,
          QuestionsId: 1111,
          Desc: "אמשיך להמתין",
          NextQuestionId: 1111,
          NextQuestions: null,
          Seq: 1,
        },
        {
          Id: 2222,
          QuestionsId: 1111,
          Desc: "אקבל הודעה לנייד ברגע שימצא נציג מתאים",
          NextQuestionId: 1111,
          NextQuestions: null,
          Seq: 1,
        },
      ];
      setCurrentQuestion({
        Questions: question,
        QuestionsOptions: questionOptions,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startChat]);

  function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const scrollToBottom = () => {
    setTimeout(
      () => lastElRef.current?.scrollIntoView({ behavior: "smooth" }),
      0
    );
  };
  const questionnairEnd = (e: React.MouseEvent, option: IQuestionOption) => {
    setIsLoading(true);

    const date = formatDateTimeInIsrael(new Date());
    setMessages({
      messageId: `o${option.Id}`,
      id: user.id,
      date: date,
      unread: false,
      message: option.Desc,
      nextQuestionId: option.NextQuestionId,
    });
    setOptions([]);
    setAnswers({
      Id: "0",
      QuestionnaireId: questionnaireId.toString(),
      QuestionsId: currentQuestionId.toString(),
      UsersId: Number(user.id) || null,
      Answer: null,
      AnswerDate: null,
      QuestionsOptionsIds: option.Id.toString(),
      Cycle: null,
    });
    const media = location.state?.pathname.split("-")[1];
    const MediaId = media === "chat" ? 1 : media === "video" ? 2 : 3;
    const appointment: THelpAppointment = {
      Active: 1,
      Id: 0,
      CompanyId: user.companyId,
      UsersId: Number(user.id),
      AppointmentsTypeId: 0,
      MediaId: MediaId,
      StatusId: 1,
    };
    if (option.Id === 1111) {
      setMessages({
        messageId: `waiting to `,
        id: prevencherId,
        date: date,
        unread: false,
        message: "בסדר גמור,\n עוד כמה רגעים יעלה מולך איש צוות",
        nextQuestionId: 0,
      });
      setLoaderMessage("אנחנו מאתרים איש צוות,\n אנא המתן מספר רגעים");
      appointment.AppointmentsTypeId = 1;
      postAppointment({ type: 2, appointment: appointment, QuestionnaireCompaniesScoreUsersId: QuestionnaireCompaniesScoreUsersId });
    } else if (option.Id === 2222) {
      navigate("/home");
      appointment.AppointmentsTypeId = 2;
      postAppointment({ type: 2, appointment: appointment, QuestionnaireCompaniesScoreUsersId: QuestionnaireCompaniesScoreUsersId });
      changeCategory(e, Category.Day);
    }
  };
  const onOptionClick = (e: React.MouseEvent, option: IQuestionOption) => {
    if (!startChat) {
      setIsLoading(true);
      const date = formatDateTimeInIsrael(new Date());
      setMessages({
        messageId: `o${option.Id}`,
        id: user.id,
        date: date,
        unread: false,
        message: option.Desc,
        nextQuestionId: option.NextQuestionId,
      });
      setOptions([]);
      setAnswers({
        Id: "0",
        QuestionnaireId: questionnaireId.toString(),
        QuestionsId: currentQuestionId.toString(),
        UsersId: Number(user.id) || null,
        Answer: null,
        AnswerDate: null,
        QuestionsOptionsIds: option.Id.toString(),
        Cycle: null,
      });
      delay(1500).then(() => {
        if (option.NextQuestionId) {
          getNextQuestionById({
            currentQuestionId: currentQuestionId,
            nextQuestionId: option.NextQuestionId,
            skip: false,
          });
        } else {
          setIsEnd(true);
        }
      });
    } else {
      questionnairEnd(e, option);
    }
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let sanitizedContent = DOMPurify.sanitize(e.target.value, {
      ALLOWED_TAGS: [], // Allow only certain tags, or use [] for none
      ALLOWED_ATTR: [], // Allow no attributes
    });
    currentQuestion?.Id === 24
      ? handleTelInputChange(e)
      : setInputValue(sanitizedContent);
  };
  const onInputSubmit = () => {
    if (!inputValue) {
      return;
    }
    if (currentQuestion?.Id === 24 && !israeliPhoneRegExp.test(inputValue)) {
      setMessages({
        messageId: `${inputValue}error`,
        id: prevencherId,
        date: formatDateTimeInIsrael(new Date()),
        unread: false,
        message: `${inputValue} - מספר נייד לא תקין`,
        nextQuestionId: currentQuestion?.NextQuestionId,
      });
      return;
    }
    setShowInput(false);
    setShowTextArea(false);
    setIsLoading(true);
    const date = formatDateTimeInIsrael(new Date());
    setMessages({
      messageId: `${currentQuestionId}${inputValue}`,
      id: user.id,
      date: date,
      unread: false,
      message: inputValue,
      nextQuestionId: currentQuestion?.NextQuestionId,
    });
    delay(1500).then(() => {
      setAnswers({
        Id: "0",
        QuestionnaireId: questionnaireId.toString(),
        QuestionsId: currentQuestionId.toString(),
        UsersId: Number(user.id) || "0",
        Answer: inputValue,
        AnswerDate: null,
        Cycle: null,
      });
      setIsLoading(false);
      setInputValue("");
      if (!currentQuestion?.NextQuestionId) {
        setIsEnd(true);
      }
    });
  };
  const handleTelInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = event.target.value;
    const onlyNumbers = inputValue.replace(/[^0-9]/g, ""); // Remove any non-numeric characters
    setInputValue(onlyNumbers);
  };
  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === "Enter") {
      onInputSubmit();
    }
  };
  const handleFocus = () => {
    inputRef?.current &&
      inputRef.current.scrollIntoView({ behavior: "smooth" });
  }; //for ios mobile
  return (
    <div className={`${prevencherChatStyles.section}`}>
      {messages.length > 0 &&
        messages.map((message, index) => (
          <Message
            key={index}
            isOwnMessage={message.id !== prevencherId}
            therapist={venchyData}
            message={message}
          />
        ))}
      {showInput && (
        <div
          className={`${prevencherChatStyles.inputContainer} ${prevencherChatStyles.inputContainer_position_left}`}
        >
          <input
            type={"text"}
            ref={inputRef}
            onFocus={handleFocus}
            autoFocus={true}
            required={true}
            className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
            value={inputValue}
            maxLength={currentQuestion?.Id === 23 ? 10 : 300}
            onChange={handleInputChange}
            onKeyDown={(event) => handleKeyDown(event)}
            onClick={() => {}}
          />
          <button
            type={"button"}
            className={prevencherChatStyles.sendBtn}
            onClick={onInputSubmit}
          />
        </div>
      )}
      {showTextArea && (
        <div
          className={`${prevencherChatStyles.inputContainer} ${prevencherChatStyles.inputContainer_position_left}`}
        >
          <textarea
            autoFocus={true}
            required={true}
            className={`${prevencherChatStyles.variant}  ${prevencherChatStyles.input}`}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={(event) => handleKeyDown(event)}
          />
          <button
            type={"button"}
            className={prevencherChatStyles.sendBtn}
            onClick={onInputSubmit}
          />
        </div>
      )}
      {isLoading && (
        <div>
          <Loader />
          <p className={prevencherChatStyles.typing}>{loaderMessage}</p>
        </div>
      )}
      {messages.length > 0 && options.length > 0 && (
        <ul className={prevencherChatStyles.variants}>
          {options.map((o, index) => (
            <li
              className={`${prevencherChatStyles.variant}  hover`}
              key={index}
              onClick={(e) => onOptionClick(e, o)}
            >
              <span>{replaceVariables(o.Desc, user)}</span>
            </li>
          ))}
        </ul>
      )}
      <div ref={lastElRef} />
    </div>
  );
}

export default PrevencherChatUrgent;

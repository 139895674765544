import {
  IAppointmentExample,
  IAppointmentDay,
  IUser,
  IAppointment,
} from "../types/types";
import { Dispatch, SetStateAction } from "react";

export function getDate(date: string) {
  const messageDate = new Date(date);
  const now = new Date();
  const messageYear = messageDate.getFullYear();
  const messageMonth = messageDate.getMonth();
  const messageDay = messageDate.getDate();
  const yearNow = now.getFullYear();
  const monthNow = now.getMonth();
  const dayNow = now.getDate();
  if (
    messageYear === yearNow &&
    messageMonth === monthNow &&
    messageDay === dayNow
  ) {
    return "היום";
  } else if (
    messageYear === yearNow &&
    messageMonth === monthNow &&
    dayNow - messageDay === 1
  ) {
    return "אתמול";
  }
  return `${messageDay}/${messageMonth}/${messageYear}`;
}

const months = [
  "ינואר",
  "פברואר",
  "מרץ",
  "אפריל",
  "מאי",
  "יוני",
  "יולי",
  "אוגוסט",
  "ספטמבר",
  "אוקטובר",
  "נובמבר",
  "נובמבר",
];

export const israeliPhoneRegExp =
  /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/;

export const getMonth = (date: Date) => {
  const month = date.getMonth();
  return months[month];
};

const formatTime = (hours: number, minutes: number) => 
  `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;


export function getDuration(date: Date, start: string, end: string): string { 
  const [startHours, startMinutes] = start.split(":").map(Number);
  const [endHours, endMinutes] = end.split(":").map(Number);

  const startDate = new Date(date);
  startDate.setHours(startHours, startMinutes);

  const endDate = new Date(date);
  endDate.setHours(endHours, endMinutes);

  return `${formatTime(startDate.getHours(), startDate.getMinutes())}-${formatTime(endDate.getHours(), endDate.getMinutes())}`;
}
export const getTimeFromTimestamp = (date: string | Date) => {
  if (typeof date !== "string") {
    date = formatDateTimeInIsrael(date);
  }
  const timestamp = date.split("T");
  return `${timestamp[1].slice(0, 5)}`;
};

export function getPrintDate(date: Date): string {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

export function isExpired(date: Date): boolean {
  const now = new Date();
  return date < now;
}
export function groupAppointmentsByDate(
  appointments: IAppointmentExample[]
): IAppointmentDay[] {
  const groups: any = appointments?.reduce((groups, a) => {
    const date = a.date.toDateString();
    // @ts-ignore
    if (!groups[date]) {
      // @ts-ignore
      groups[date] = [];
    }
    // @ts-ignore
    groups[date].push(a);
    return groups;
  }, {});

  // Edit: to add it in the array format instead
  return Object.keys(groups).map((date) => {
    return {
      date,
      appointments: groups[date],
    };
  });
}
export const passwordCheck = (
  value: string
): {
  strength: number;
  val: string;
} => {
  let pwdCheck = 0;
  let validateRegex = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];

  value.length > 5 &&
    validateRegex.forEach((regex) => {
      if (new RegExp(regex).test(value)) {
        pwdCheck += 1;
      }
    });
  switch (pwdCheck) {
    case 2:
      return {
        strength: 2,
        val: "בינוני",
      };
    case 3:
      return {
        strength: 3,
        val: "חזק",
      };

    default:
      return {
        strength: 1,
        val: "חלש",
      };
  }
};

export function lowercaseKeys(obj: object): object {
  return Object.entries(obj).reduce((carry, [key, value]) => {
    // @ts-ignore
    carry[key.charAt(0).toLowerCase() + key.slice(1)] = value;
    return carry;
  }, {});
}

type ChangedValues = Partial<IUser>;

export const getChangedValues = (
  values: IUser,
  initialValues: IUser
): ChangedValues => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key] !== value;

    if (hasChanged) {
      acc[key as keyof IUser] = value;
    }

    return acc;
  }, {} as ChangedValues);
};

export function isSameDateAndTime(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate() &&
    date1.getHours() === date2.getHours() &&
    date1.getMinutes() === date2.getMinutes()
  );
}

export function replaceVariables(text: string, userData: IUser): string {
  if (!text || !userData) return "";
  const userName = userData.firstName;
  const userGender = userData.genderId;
  // Replace @name with user name
  let replacedString = text.replace(/(@name|name@)/gi, userName);
  if (text.toLowerCase().includes("gender")) {
    replacedString = replacedString.replace(
      /(@gender_know|gender_know@)/gi,
      userGender === "נקבה" ? "יודעת" : "יודע"
    );
    replacedString = replacedString.replace(
      /(@gender_ready|gender_ready@)/gi,
      userGender === "נקבה" ? "מוכנה" : "מוכן"
    );
    replacedString = replacedString.replace(
      /(@gender|gender@)/gi,
      userGender === "נקבה" ? "את" : "אתה"
    );
  }

  return replacedString;
}

export const formatDateTimeInIsrael = (dateInput: Date | string): string => {
  // Convert input to a Date object if it's a string
  const date = new Date(dateInput);

  // Use Intl.DateTimeFormat to get the date in Israel time
  const israelTime = new Intl.DateTimeFormat("en-GB", {
    timeZone: "Asia/Jerusalem", // The IANA time zone identifier for Israel
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Use 24-hour format
  }).format(date);

  // Extract the components from the formatted Israel time
  const [formattedDate, formattedTime] = israelTime.split(", ");
  const [day, month, year] = formattedDate.split("/");
  const [hours, minutes] = formattedTime.split(":");

  // Format to desired output (YYYY-MM-DDTHH:mm:ssZ)
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export function formatAppointmentDate(dateStr: string): string {
  const date = new Date(dateStr);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset to start of the day

  const dayDifference = Math.floor(
    (date.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
  );

  let prefix = "";
  if (dayDifference === 0) {
    prefix = "היום";
  } else if (dayDifference === 1) {
    prefix = "מחר";
  } else if (dayDifference > 1 && dayDifference <= 7) {
    prefix = "שבוע הבא";
  }

  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const formattedDate = new Intl.DateTimeFormat("he-IL", options).format(date);

  return prefix ? `${prefix}, ${formattedDate}` : formattedDate;
}

export const filterAppointments = (
  appointments: IAppointment[]
): IAppointment[] => {
  // Map to keep track of the highest ID appointment for each user
  const uniqueAppointmentsMap = new Map<number, IAppointment>();

  appointments.forEach((appointment) => {
    const existingAppointment = uniqueAppointmentsMap.get(
      appointment.appointments.therapistsId
    );
    if (
      !existingAppointment ||
      appointment.appointments.id > existingAppointment.appointments.id
    ) {
      uniqueAppointmentsMap.set(
        appointment.appointments.therapistsId,
        appointment
      );
    }
  });

  // Convert the map values to an array of IChatAppointment
  return Array.from(uniqueAppointmentsMap.values());
};

export const toggleMuteMic = (
  mediaStream: MediaStream | undefined,
  setIsMicMuted: (a: boolean) => void
) => {
  if (mediaStream) {
    const audioTrack = mediaStream.getAudioTracks()[0];
    if (audioTrack) {
      audioTrack.enabled = !audioTrack.enabled;
      setIsMicMuted(!audioTrack.enabled); // Update state to reflect mute/unmute
    }
  }
};

export const toggleVideo = (
  mediaStream: MediaStream | undefined,
  setIsVideoEnabled: Dispatch<SetStateAction<boolean>>
) => {
  if (mediaStream) {
    const videoTracks = mediaStream.getVideoTracks();
    if (videoTracks.length > 0) {
      // Toggle track.enabled (do not stop the track)
      const isEnabled = videoTracks[0].enabled;
      videoTracks.forEach((track) => {
        if (track.readyState === "live") {
          track.enabled = !track.enabled;
        }
      });
      setIsVideoEnabled(!isEnabled);
    }
  }
};

export function isToday(date: Date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

import styles from "./myPower.module.css";
import React, { useRef, useState } from "react";
import CircularProgress from "../progressBar/progressBar";
import BackArrowButton from "../backArrowButton/backArrowButton";

function MyPower() {
  const [progressValue, setProgressValue] = useState(800);
  const pathRef = useRef<SVGPathElement | null>(null);
  const [showExplanation, setShowExplanation] = useState(false);

  return (
    <section className={styles.section}>
      <h2
        className={`${styles.section__title} ${
          showExplanation && styles.section__title_noMargin
        }`}
      >
        דירוג החוסן שלי
      </h2>
      {!showExplanation ? (
        <article className={styles.section__content}>
          <article className={styles.progress}>
            <p className={styles.progressNumber}>1000</p>
            <div className={styles.progressBar}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="223"
                height="169"
                viewBox="0 0 223 169"
                fill="none"
              >
                <path
                  ref={pathRef}
                  d="M20.4919 161C12.5184 146.318 8 129.492 8 111.617C8 54.393 54.3405 8 111.5 8C168.66 8 215 54.393 215 111.617C215 129.32 210.575 145.974 202.758 160.546"
                  stroke="#E0E0E0"
                  strokeWidth="16"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {
                <CircularProgress
                  progressValue={progressValue}
                  pathRef={pathRef}
                />
              }
              <div className={styles.progressContent}>
                <p className={styles.progressContentNumber}>700</p>
                <p className={styles.progressContentText}>
                  +15 נקודות מהדירוג הקודם
                </p>
                <p className={styles.progressContentTag}>כל הכבוד!</p>
              </div>
            </div>
            <p className={styles.progressNumber}>0</p>
          </article>
          <article className={styles.about}>
            <h4 className={styles.aboutTitle}>מה אומר הציון:</h4>
            <p className={styles.aboutText}>
              לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד
              אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק
              סכתצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג -
              ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה. לפמעט מוסן מנת.
              אדיפיסינג אלית קולורס מונפרד אדנדום סילקוף, מרגשי ומרגשח.
            </p>
          </article>
        </article>
      ) : (
        <div className={styles.explanations}>
          <BackArrowButton
            position={"center"}
            onClick={() => setShowExplanation(false)}
          />
          <div className={styles.explanationsInfo}>
            <p className={styles.explanationsText}>
              לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד
              אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט
              דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל
              אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז
              דומור ליאמום בלינך רוגצה. לפמעט מוסן מנת.
            </p>
            <p className={styles.explanationsText}>
              לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית קולורס מונפרד
              אדנדום סילקוף, מרגשי ומרגשח. עמחליף מוסן מנת. להאמית קרהשק סכעיט
              דז מא, מנכם למטכין נשואי מנורך. סחטיר בלובק. תצטנפל בלינדו למרקל
              אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז
              דומור ליאמום בלינך רוגצה. לפמעט מוסן מנת.
            </p>
          </div>
          <h3
            className={`${styles.section__title} ${styles.section__subtitle}`}
          >
            איך אני משפר את הדירוג שלי?
          </h3>
          <ul className={styles.explanationsList}>
            <li className={styles.explanationsLi}>
              <div className={styles.explanationsLiHeader}>
                <p className={styles.explanationsLiNumber}>1</p>
                <p className={styles.explanationsLiHeaderText}>
                  השתדל לבצע את התרגילים
                </p>
              </div>
              <p className={styles.explanationsLiText}>
                התרגילים חשובים, ומעניקים לך המשך טיפול גם כשאתה לא בפגישה.
                הנסיון מלמד שמי שמתרגל בצורה רציפה הופך להיות חסין יותר מבחינה
                נפשית וסובל פחות מתנודות במצבי רוח
              </p>
            </li>
            <li className={styles.explanationsLi}>
              <div className={styles.explanationsLiHeader}>
                <p className={styles.explanationsLiNumber}>2</p>
                <p className={styles.explanationsLiHeaderText}>
                  השתדל לבצע את התרגילים
                </p>
              </div>
              <p className={styles.explanationsLiText}>
                התרגילים חשובים, ומעניקים לך המשך טיפול גם כשאתה לא בפגישה.
                הנסיון מלמד שמי שמתרגל בצורה רציפה הופך להיות חסין יותר מבחינה
                נפשית וסובל פחות מתנודות במצבי רוח
              </p>
            </li>
            <li className={styles.explanationsLi}>
              <div className={styles.explanationsLiHeader}>
                <p className={styles.explanationsLiNumber}>3</p>
                <p className={styles.explanationsLiHeaderText}>
                  השתדל לבצע את התרגילים
                </p>
              </div>
              <p className={styles.explanationsLiText}>
                התרגילים חשובים, ומעניקים לך המשך טיפול גם כשאתה לא בפגישה.
                הנסיון מלמד שמי שמתרגל בצורה רציפה הופך להיות חסין יותר מבחינה
                נפשית וסובל פחות מתנודות במצבי רוח
              </p>
            </li>
            <li className={styles.explanationsLi}>
              <div className={styles.explanationsLiHeader}>
                <p className={styles.explanationsLiNumber}>4</p>
                <p className={styles.explanationsLiHeaderText}>
                  השתדל לבצע את התרגילים
                </p>
              </div>
              <p className={styles.explanationsLiText}>
                התרגילים חשובים, ומעניקים לך המשך טיפול גם כשאתה לא בפגישה.
                הנסיון מלמד שמי שמתרגל בצורה רציפה הופך להיות חסין יותר מבחינה
                נפשית וסובל פחות מתנודות במצבי רוח
              </p>
            </li>
            <li className={styles.explanationsLi}>
              <div className={styles.explanationsLiHeader}>
                <p className={styles.explanationsLiNumber}>5</p>
                <p className={styles.explanationsLiHeaderText}>
                  השתדל לבצע את התרגילים
                </p>
              </div>
              <p className={styles.explanationsLiText}>
                התרגילים חשובים, ומעניקים לך המשך טיפול גם כשאתה לא בפגישה.
                הנסיון מלמד שמי שמתרגל בצורה רציפה הופך להיות חסין יותר מבחינה
                נפשית וסובל פחות מתנודות במצבי רוח
              </p>
            </li>
          </ul>
        </div>
      )}
      {!showExplanation && (
        <button className={styles.btn} onClick={() => setShowExplanation(true)}>
          ממה מושפע הדירוג שלי?
        </button>
      )}
    </section>
  );
}

export default MyPower;

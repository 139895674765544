import homePageStyles from "./home.module.css";
import Questionnaire from "../../components/questionnaire/questionnaire";
import MyDay from "../../components/myDay/myDay";
import { Category, ISubCategory, SubCategories } from "../../types/types";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { homePageContents } from "../../utils/homePageContent";
import { subCategories } from "../../utils/categories";
import ContactUs from "../../components/contactUs/contactUs";
import Modal from "../../components/modals/modal/Modal";
import Cookies_popup from "../../components/modals/cookies_popup/cookies_popup";
import { userSelector } from "../../services/selectors/user.selectors";
import { useAppSelector } from "../../services/hooks";
import { testUserEmail, testUserEmail2 } from "../../utils/constants";

interface IHomePageProps {
  activeCategory: Category;
  setCategory: Dispatch<SetStateAction<ISubCategory>>;
}
function HomePage({
  activeCategory = Category.Day,
  setCategory,
}: // setTreatmentCategory,
  IHomePageProps): JSX.Element {
  const [showCookiesPopup, setShowCookiesPopup] = useState(false);
  const user = useAppSelector(userSelector);

  const isTestUser =
    user?.email.toLowerCase() === testUserEmail ||
    user?.email.toLowerCase() === testUserEmail2;

  useEffect(() => {
    let cookiesTimeout: number | undefined;
    let pop_status = localStorage.getItem("pop_status");
    if (!pop_status) {
      cookiesTimeout = window.setTimeout(() => setShowCookiesPopup(true), 5000);
      localStorage.setItem("pop_status", "true");
    }
    return () => {
      window.clearTimeout(cookiesTimeout);
    };
  }, []);
  const content = useMemo(
    () => homePageContents.find((c) => c.value === activeCategory),
    [activeCategory]
  );
  return (
    <>
      {showCookiesPopup && (
        <Modal onClose={() => setShowCookiesPopup(false)}>
          <Cookies_popup onClose={() => setShowCookiesPopup(false)} />
        </Modal>
      )}
      <Questionnaire />
      {activeCategory === Category.Day ? (
        <MyDay />
      ) : activeCategory === Category.Contacts ? (
        <ContactUs />
      ) : (
        <div className={homePageStyles.section}>
          <h3 className={homePageStyles.section__title}>{content?.title}</h3>
          <ul className={homePageStyles.section__categories}>
            {content?.sections?.map((s, index) => (
              <li key={index}>
                <Link
                  to={(!s.disabled || isTestUser) ? s.navigateTo : '#'}
                  state={s?.state}
                  className={`${homePageStyles.section__category} ${(s.disabled && !isTestUser) && homePageStyles.section__disabled} hover`}
                  onClick={() => {
                    const categoryKey = s.value as SubCategories;
                    if ((!s.disabled || isTestUser) && subCategories.hasOwnProperty(categoryKey)) {
                      setCategory(subCategories[categoryKey] as ISubCategory);
                    }
                  }}
                >
                  <div className={homePageStyles.section__sectionName}>
                    <img
                      src={s.icon}
                      className={`${homePageStyles.section__icon}`}
                      alt={s.text}
                    />
                    <p className={homePageStyles.section__text}>{s.text}</p>
                  </div>
                  <button
                    type="button"
                    className={homePageStyles.section__sectionArrowBtn}
                  />
                </Link>
              </li>
            ))}
          </ul>
        </div >
      )
      }
    </>
  );
}

export default HomePage;

import styles from "./videoCallWindow.module.css";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Peer from "simple-peer";
import incomingCallSound from "../../../sounds/incomingCallSound.wav";
import { useSelector } from "react-redux";
import ModalOverlay from "../modalOverlay/ModalOverlay";
import { toggleMuteMic, toggleVideo } from "../../../utils/utils";
import acceptCall from "../../../images/answer_call_icon.svg";
import endCall from "../../../images/end_call_icon.svg";
import {
  callEndedSelector,
  chatStateSelector,
} from "../../../services/selectors/chat.selector";
import { useActions } from "../../../services/hooks/useActions";
import { videoCallInitialState } from "../../../services/slices/chat.slice";
import { END_CALL_CODE } from "../../../utils/constants";
import { useAppSelector } from "../../../services/hooks";
import { userSelector } from "../../../services/selectors/user.selectors";

function VideoCallWindow() {
  const user = useAppSelector(userSelector);
  const myVideo = useRef<HTMLVideoElement>(null);
  const userVideo = useRef<HTMLVideoElement>(null);
  const peerRef = useRef<Peer.Instance | null>(null);
  const callEnded = useAppSelector(callEndedSelector);

  const chatState = useSelector(chatStateSelector);

  const [isMicMuted, setIsMicMuted] = useState(false);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);

  const {
    setCallAccepted,
    setCallEnded,
    setUserStream,
    setCall,
    setStartCall,
    // endCall,
  } = useActions();
  const videoTracks = chatState?.userStream?.getVideoTracks();

  // console.log(chatState);
  // console.log("Video Tracks:", chatState.myStream?.getVideoTracks());
  // chatState.myStream?.getVideoTracks().forEach((track) => {
  //   console.log("Track State:", track.readyState, "Enabled:", track.enabled);
  // });

  if (videoTracks?.length === 0) {
    console.log("No video tracks available");
  } else {
    // console.log("Video tracks found", videoTracks);
  }
  const userVideoElement = userVideo.current;
  userVideoElement?.addEventListener("error", (event) => {
    console.error("Error with video playback:", event);
  });

  useEffect(() => {
    if (myVideo?.current) {
      myVideo.current.srcObject = chatState.myStream as MediaProvider;
    }
    if (userVideo?.current) {
      userVideo.current.srcObject = chatState.userStream as MediaProvider;
    }
    // console.log(
    //   "Is srcObject set?",
    //   userVideo?.current?.srcObject === chatState.userStream
    // );

    return () => {
      // Cleanup: Ensure video is enabled when the component unmounts
      if (chatState.myStream) {
        chatState.myStream.getVideoTracks().forEach((track) => {
          track.enabled = true; // Re-enable the video track
        });
        setIsVideoEnabled(true); // Reset state for video toggle
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myVideo, userVideo, chatState.userStream, chatState.myStream]);
  const { avatar } = chatState.call.from;

  const answerCall = useCallback(
    () => {
      setCallEnded(undefined);
      peerRef.current = new Peer({
        initiator: false,
        trickle: false,
        stream: chatState.myStream,
        config: {
          iceServers: [
            { urls: "stun:stun.l.google.com:19302" },
            { urls: "stun:stun1.l.google.com:19302" },
            { urls: "stun:stun.services.mozilla.com" },
          ],
        },
      });
      const receiver = chatState.chatUsers.find(
        (u) => u.fullName === chatState.call.from.name
      );
      console.log(receiver);
      peerRef.current.on("signal", (data) => {
        chatState.connection?.invoke(
          "SendAnswer",
          receiver?.userId,
          JSON.stringify(data)
        );
      });

      peerRef.current.on("stream", (stream) => setUserStream(stream));

      if (chatState?.offer) {
        peerRef.current.signal(JSON.parse(chatState.offer));
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      chatState?.offer,
      // chatState.myStream,
      // receiver?.userId,
      setUserStream,
    ]
  );

  const leaveCall = useCallback(() => {
    // endCall();
    setCall(videoCallInitialState);
    setCallAccepted(false);
    setStartCall(false);
    if (peerRef.current) peerRef.current.destroy();
    const receiver = chatState.chatUsers.find(
      (u) => u.fullName === chatState.call.from.name
    );
    console.log(receiver);

    chatState.connection?.invoke("SendMessage", {
      type: END_CALL_CODE,
      senderId: user.id,
      content: `${END_CALL_CODE}:by:${user.fullName || ""}`,
      receiverId: receiver?.userId,
    });
  }, [setCall, setCallAccepted, setStartCall]);

  useEffect(() => {
    if (callEnded) {
      setCall(videoCallInitialState);
      setCallAccepted(false);
      setStartCall(false);
      // Destroy the peer connection if it exists
      if (peerRef.current) {
        peerRef.current.destroy();
        peerRef.current = null; // Optionally set it to null after destruction
      }
    }
  }, [callEnded]);

  // endCall if therapist offline
  useEffect(() => {
    if (chatState.callAccepted || chatState.call.isReceivingCall) {
      const therapist = chatState.chatUsers.find(
        (u) => u.fullName === chatState.call.from.name
      );
      if (therapist?.online === false) {
        console.log(therapist, "offline");
        setCall(videoCallInitialState);
        setCallAccepted(false);
        setStartCall(false);
        // Destroy the peer connection if it exists
        if (peerRef.current) {
          peerRef.current.destroy();
          peerRef.current = null;
        }
      }
    }
  }, [chatState]);

  return (
    <>
      <div className={`${styles.window} `}>
        {!chatState.callAccepted && (
          <audio src={incomingCallSound} autoPlay={true} />
        )}
        <div className={styles.videoContainer}>
          <h3 className={styles.header}>
            {chatState.call.from.name} מתקשר/ת...
          </h3>

          {chatState.myStream && (
            <>
              <video
                playsInline
                ref={myVideo}
                muted
                autoPlay
                className={styles.myVideo}
              />
              <div className={styles.userVideoWindow}>
                {!chatState.callAccepted ? (
                  <div className={`${styles.outgoingCall}`}>
                    <div className={styles.pulse} />
                    {avatar && (
                      <img
                        src={avatar}
                        alt={"avatar"}
                        className={`${styles.avatar}`}
                      />
                    )}
                  </div>
                ) : (
                  <video
                    playsInline
                    ref={userVideo}
                    autoPlay
                    className={styles.userVideo}
                  />
                )}
                <div className={styles.buttonsBar}>
                  {!chatState.callAccepted ? (
                    <button
                      type={"button"}
                      className={`hover ${styles.btn}`}
                      onClick={() => {
                        setCallAccepted(true);
                        answerCall();
                      }}
                    >
                      <img
                        alt={"קבל שיחה"}
                        className={styles.btn__image}
                        src={acceptCall}
                      />
                    </button>
                  ) : (
                    <>
                      <button
                        type={"button"}
                        className={`hover ${styles.btn} `}
                        onClick={() => {
                          toggleMuteMic(chatState.myStream, setIsMicMuted);
                        }}
                      >
                        {/*<img*/}
                        {/*  className={` ${styles.btn_type_mute} ${styles.btn__image}`}*/}
                        {/*  alt={"mute"}*/}
                        {/*  src={isMicMuted ? microphoneIcon : microphoneSlashIcon}*/}
                        {/*/>*/}

                        <span
                          className={`${styles.btn_type_mute} ${
                            isMicMuted && styles.btn_type_unmute
                          }`}
                        />
                      </button>
                      <button
                        type={"button"}
                        className={`hover ${styles.btn} `}
                        onClick={() => {
                          toggleVideo(chatState.myStream, setIsVideoEnabled);
                        }}
                      >
                        <span
                          className={`${styles.btn_type_mute} ${
                            styles.btn_type_video
                          } ${
                            !isVideoEnabled && styles.btn_type_video_inactive
                          }`}
                        />
                      </button>
                    </>
                  )}

                  <button
                    type={"button"}
                    className={`hover ${styles.btn}`}
                    onClick={() => {
                      leaveCall();
                    }}
                  >
                    <img
                      alt="לחצן סיום שיחה"
                      className={styles.btn__image}
                      src={endCall}
                    />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ModalOverlay onClick={() => setStartCall(false)} />
    </>
  );
}

export default VideoCallWindow;

import styles from "./addNewReport.module.css";
import React, { useState } from "react";
import Modal from "../modals/modal/Modal";
import SupportSuccessPopup from "../modals/support_success_popup/support_success_popup";
import { filterOptions, newReportSubjects } from "../../utils/constants";
import { ISubCategory } from "../../types/types";

const initialValues: {
  theme: ISubCategory;
  subjects: string[];
} = {
  theme: { title: "בחירה", value: "none" },
  subjects: [],
};
function AddNewReport() {
  const [form, setForm] = useState(initialValues);
  const [openModal, setOpenModal] = useState(false);
  const [showThemes, setShowThemes] = useState(false);

  const isDisabled = form.theme.value === "none" || form.subjects.length === 0;

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(form);
    setForm(initialValues);
    setOpenModal(true);
  };

  return (
    <article className={styles.section}>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <SupportSuccessPopup onClose={() => setOpenModal(false)} />
        </Modal>
      )}
      <p className={styles.section__text}>
        גישה רק לדברים החיוניים ביותר. שדרג לפרימיום וקבל שירותי תמיכה נפשית על
        ידי פסיכולוג מוסמך זמין 24/7, התייעצות בפורום עם מתמודדים כמוך, ועוד
      </p>
      <form className={styles.form} onSubmit={submitForm}>
        <p className={styles.form__label}>בחירת תקופת דו״ח</p>
        <div className={styles.form__themesContainer}>
          <p
            className={styles.form__input}
            onClick={(e) => {
              e.stopPropagation();
              setShowThemes(true);
            }}
          >
            {form.theme.title}
          </p>
          {showThemes && (
            <ul className={styles.form__themes}>
              {filterOptions.map(
                (t, index) =>
                  t.value !== "date" && (
                    <li
                      className={`${styles.form__theme} hover`}
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowThemes(false);
                        setForm({ ...form, theme: t });
                      }}
                    >
                      {t.title}
                    </li>
                  )
              )}
            </ul>
          )}
        </div>
        <label htmlFor="subject" className={styles.form__label}>
          נושאים
        </label>
        <ul className={styles.subjectsList}>
          {newReportSubjects.map((s, index) => (
            <li
              key={index}
              className={`${styles.subjectsLi} ${
                form.subjects.indexOf(s.value) >= 0 && styles.subjectsLi_active
              }`}
              onClick={() =>
                setForm((prev) => {
                  const index = prev.subjects.indexOf(s.value);
                  if (index >= 0) {
                    return {
                      ...prev,
                      subjects: prev.subjects.filter((sub) => sub !== s.value),
                    };
                  } else
                    return { ...prev, subjects: [...prev.subjects, s.value] };
                })
              }
            >
              <input
                name={s.value}
                id={s.value}
                type={"checkbox"}
                value={s.value}
                className={styles.subjects__checkbox}
                checked={form.subjects.indexOf(s.value) >= 0}
                onChange={() => {}}
              />
              <label
                htmlFor={s.value}
                className={styles.subjectsLabel}
                onClick={(e) => e.stopPropagation()}
              >
                {s.title}
              </label>
            </li>
          ))}
        </ul>
        <button
          type={"submit"}
          className={`button ${styles.form__btn} ${
            isDisabled && styles.form__btn_disabled
          } hover`}
          disabled={isDisabled}
        >
          יצירת דו״ח התקדמות חדש
        </button>
      </form>
    </article>
  );
}

export default AddNewReport;

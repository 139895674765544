import searchPageStyles from "./searchPage.module.css";
import { disorders } from "../../utils/disorders";
import TherapistCard from "../../components/therapistCard/therapistCard";
import React, { useEffect, useState } from "react";
import { ITherapist } from "../../types/types";
import {
  useGetFieldsQuery,
  useLazyGetTherapistsQuery,
} from "../../services/api/therapists.api";
import Questionnaire from "../../components/questionnaire/questionnaire";
import Matching from "../../components/matching/matching";
import Therapist from "../../components/therapist/therapist";
import MakeAppointment from "../../components/makeAppointment/makeAppointment";
import useMediaQuery from "../../hooks/useMediaQuery";
import { mobileWidth } from "../../utils/constants";
import { useLocation } from "react-router-dom";

function SearchPage() {
  const mobile = useMediaQuery(mobileWidth);
  const location = useLocation();

  const [search, setSearch] = useState<"type" | "list">("type");
  const [value, setValue] = useState<string>("");
  const [fieldIndex, setFieldIndex] = useState<number>(0);
  const [variants, setVariants] = useState<string[]>([]);
  const [therapists, setTherapists] = useState<ITherapist[]>([]);
  const [therapist, setTherapist] = useState<ITherapist | undefined>(undefined);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showMatching, setShowMatching] = useState(false);
  const [appointmentId, setAppointmentId] = useState(null);

  const { isError } = useGetFieldsQuery();
  const [
    fetchTherapists,
    { isError: fetchTherapistsIsError },
  ] = useLazyGetTherapistsQuery();

  useEffect(() => {
    if (location.state?.appointmentData) {
      const { appointmentData } = location.state;
      setAppointmentId(appointmentData.id);
      setShowCalendar(true);
      // setTherapist(
      ///////////////////////////// have to use a realy therapists list
      // );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!value.length) {
      setVariants([]);
    } else {
      setVariants(disorders.filter((d) => d.includes(value) && d !== value));
    }
  }, [value]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTherapists([]);
    setValue(e.target.value);
  };

  const findTherapists = () => {
    // const t = testData.therapists.filter((t) => t.fields.indexOf(value) > 0);
    // setTherapists(testData.therapists); ////////////////////// have to use a realy therapists list
    setVariants([]);
  };

  return (
    <section className={searchPageStyles.page}>
      <Questionnaire />
      <h2 className={searchPageStyles.section__title}>הפגישות שלי</h2>
      {!therapist ? (
        showMatching ? (
          <Matching />
        ) : (
          <div className={searchPageStyles.section}>
            <p className={searchPageStyles.section__text}>
              ניתן לחפש מומחה לפי תחומי טיפול, או שנוכל להתאים לך מומחה לפי
              המידע שסיפקת עד כה
            </p>
            <div className={searchPageStyles.search}>
              <h2 className={searchPageStyles.search__title}>חיפוש עצמאי</h2>
              <div className={searchPageStyles.search__options}>
                <label
                  className={`${searchPageStyles.search__option} ${search !== "type" && searchPageStyles.search__option_active
                    } hover`}
                  htmlFor={"search"}
                  onClick={() => {
                    setSearch("type");
                    setVariants([]);
                  }}
                >
                  תחום טיפול
                </label>
                <button
                  type={"button"}
                  className={`${searchPageStyles.search__option} ${search !== "list" && searchPageStyles.search__option_active
                    } hover`}
                  onClick={() => {
                    setSearch("list");
                    setVariants(disorders);
                    setTherapists([]);
                  }}
                >
                  לרשימה
                </button>
              </div>
              <input
                name={"search"}
                id={"search"}
                className={searchPageStyles.search__input}
                onChange={onInputChange}
                value={value}
              />
              {variants.length > 0 && (
                <ul className={searchPageStyles.search__variants}>
                  {variants.map((v, index) => (
                    <li
                      key={index}
                      className={searchPageStyles.search__variant}
                      onClick={() => {
                        setValue(v);
                        setFieldIndex(index);
                      }}
                    >
                      {v}
                    </li>
                  ))}
                </ul>
              )}
              {therapists.length > 0 ? (
                <div className={searchPageStyles.result}>
                  <p
                    className={`${searchPageStyles.section__text} ${searchPageStyles.section__text_therapists}`}
                  >
                    {`${therapists.length} מומחים נמצאו עבור:`}
                  </p>
                  <p className={searchPageStyles.search__title}>{value}</p>
                  <ul className={searchPageStyles.therapists}>
                    {therapists.map((t, index) => (
                      <TherapistCard
                        key={index}
                        therapist={t}
                        setTherapist={setTherapist}
                      />
                    ))}
                  </ul>
                </div>
              ) : (
                <>
                  {(isError || fetchTherapistsIsError) && (
                    <p className={"error"}>
                      משהו השתבש, אנא נסה שוב מאוחר יותר
                    </p>
                  )}
                  <button
                    type={"button"}
                    className={`button ${searchPageStyles.section__btn} ${searchPageStyles.section__btn_search} hover`}
                    onClick={() => {
                      findTherapists();
                      fetchTherapists(fieldIndex);
                    }}
                  >
                    חיפוש
                  </button>
                  <div className={searchPageStyles.bottomBar}>
                    <p className={searchPageStyles.bottomBar__title}>או</p>
                    <button
                      type={"button"}
                      className={`button ${searchPageStyles.section__btn} ${searchPageStyles.section__btn_bottomBar} hover`}
                      onClick={() => {
                        setShowMatching(true);
                      }}
                    >
                      {mobile ? "או תתאימו לי מומחה" : "תתאימו לי מומחה"}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )
      ) : !showCalendar ? (
        <Therapist
          therapist={therapist}
          onClose={() => setTherapist(undefined)}
          setShowCalendar={setShowCalendar}
        />
      ) : (
        <MakeAppointment
          appointmentId={appointmentId}
          resetAppointmentId={() => setAppointmentId(null)}
          therapist={therapist}
          onClose={() => setTherapist(undefined)}
        />
      )}
    </section>
  );
}

export default SearchPage;

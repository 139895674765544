import popupStyles from "./appointmentCard.module.css";
import { IAppointment } from "../../../types/types";
import { getDuration } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useLazyGetTherapistDatesQuery } from "../../../services/api/therapists.api";
import Modal from "../modal/Modal";
import CancelAppointmentConfirmationPopup from "../cancelAppointmentConfirmation_popup/cancelAppointmentConfirmation_popup";
import CloseButton from "../../closeButton/closeButton";
import { useAppSelector } from "../../../services/hooks";
import { userSelector } from "../../../services/selectors/user.selectors";
import { testUserEmail, testUserEmail2 } from "../../../utils/constants";

interface IAppointmentCardProps {
  onClose: () => void;
  appointmentData: IAppointment;
}
function AppointmentCard({ onClose, appointmentData }: IAppointmentCardProps) {
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const user = useAppSelector(userSelector)

  const appointmentDate = new Date(appointmentData.appointments.appointmentDate)

  const isTestUser =
    user?.email.toLowerCase() === testUserEmail ||
    user?.email.toLowerCase() === testUserEmail2;


  const [fetchDates] = useLazyGetTherapistDatesQuery();

  return openModal ? (
    <Modal
      onClose={() => {
        setOpenModal(false);
        onClose();
      }}
    >
      <CancelAppointmentConfirmationPopup
        appointmentData={appointmentData}
        onClose={() => {
          setOpenModal(false);
          onClose();
        }}
      />
    </Modal>
  ) : (
    <article className={popupStyles.popup}>
      <CloseButton onClick={onClose} />
      <h2 className={popupStyles.popup__title}>פרטי פגישה</h2>
      <p className={popupStyles.popup__text}>שם המטפל</p>
      <p className={popupStyles.popup__text_big}>
        {appointmentData.therapistName}
      </p>
      <p className={popupStyles.popup__text}>תאריך פגישה</p>
      <p className={popupStyles.popup__text_big}>
        {`${appointmentDate.getDate()}/${appointmentDate.getMonth() + 1
          }/${appointmentDate.getFullYear()}`}
      </p>
      <p className={popupStyles.popup__text}>שעת פגישה</p>
      <p className={popupStyles.popup__text_big}>
        {getDuration(appointmentDate, appointmentData.appointments.startTime ?? "00:00", appointmentData.appointments?.endTime ?? "00:50")}
      </p>
      <p className={popupStyles.popup__text}>אופן הפגישה</p>
      <p className={popupStyles.popup__text_big}>
        {appointmentData.appointments.medias.name}
      </p>
      <button
        type={"button"}
        className={`${popupStyles.popup__optionsBar} ${showOptions && popupStyles.popup__optionsBar_visible
          } ${popupStyles.popup__btn} ${popupStyles.popup__nextBtn}
         ${(appointmentDate <= new Date() || !isTestUser) ? popupStyles.popup__btn_disabled : "hover"} `}
        onClick={() => {
          setShowOptions(!showOptions);
        }}
        disabled={appointmentDate <= new Date() || !isTestUser}
      >
        עריכת פרטים
      </button>
      <div
        className={`${popupStyles.popup__optionsBar} ${showOptions && popupStyles.popup__optionsBar_visible
          }`}
      >
        <p className={popupStyles.popup__text}>האם תרצה</p>
        <ul className={popupStyles.popup__options}>
          <li>
            <button
              onClick={() => setOpenModal(true)}
              className={`${popupStyles.popup__nextBtn} ${popupStyles.popup__optionBtn} 
               ${(appointmentDate <= new Date() || !isTestUser) && popupStyles.popup__btn_disabled}`}
              disabled={appointmentDate <= new Date() || !isTestUser}
            >
              לבטל פגישה
            </button>
          </li>
          <li>
            <button
              className={`${popupStyles.popup__nextBtn} ${popupStyles.popup__optionBtn}
                ${(appointmentDate <= new Date() || !isTestUser) && popupStyles.popup__btn_disabled}`}
              disabled={appointmentDate <= new Date() || !isTestUser}
              onClick={() => {
                fetchDates(appointmentData.appointments.therapistsId.toString());
                navigate("search", {
                  replace: true,
                  state: { appointmentData },
                });
              }}
            >
              לשנות פגישה
            </button>
          </li>
        </ul>
      </div>
      {(appointmentData.appointments.active && [1, 2].includes(appointmentData.appointments.mediaId)) && <button
        type={"button"}
        className={`${popupStyles.popup__btn} button 
          ${(appointmentDate <= new Date()) ? popupStyles.popup__btn_disabled : "hover"}`}
        disabled={appointmentDate <= new Date()}
        onClick={() => {
          navigate("/chat");
        }}

      >
        כניסה לפגישה      </button>}
      <button
        type={"button"}
        className={`${popupStyles.popup__btn} button 
          ${(appointmentDate <= new Date() || !isTestUser || true) ? popupStyles.popup__btn_disabled : "hover"}`}
        disabled={true || appointmentDate <= new Date() || !isTestUser}
        onClick={() => {
          navigate("search");
        }}

      >
        קביעת פגישה נוספת
      </button>
      <button
        type={"button"}
        className={` ${popupStyles.popup__btn} ${popupStyles.popup__cancelBtn}
         ${(appointmentDate <= new Date() || !isTestUser) ? popupStyles.popup__btn_disabled : "hover"} `}
        onClick={() => setOpenModal(true)}
        disabled={appointmentDate <= new Date() || !isTestUser}
      >
        ביטול הפגישה
      </button>
    </article>
  );
}

export default AppointmentCard;

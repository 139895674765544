import forumThreadStyles from "./forumThread.module.css";
import { forumThreadExample } from "../../utils/constants";
import ForumMessage from "../forumMessage/forumMessage";

function ForumThread() {
  return (
    <section className={forumThreadStyles.section}>
      <h2 className={forumThreadStyles.title}>{forumThreadExample.title}</h2>
      <ul className={forumThreadStyles.list}>
        {forumThreadExample.messages.map((m, index) => (
          <ForumMessage key={index} message={m} />
        ))}
      </ul>
    </section>
  );
}

export default ForumThread;

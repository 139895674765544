import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import chatStyles from "./chat.module.css";
import {
  chatNoteTypes,
  mobileWidth,
  noDataLabel,
  TYPING_CODE,
} from "../../utils/constants";
import useMediaQuery from "../../hooks/useMediaQuery";
import ChatSideBar from "../chatSidebar/chatSideBar";
import { IChatMessage, IUserChatData } from "../../types/types";
import { getDate } from "../../utils/utils";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import avatarPlaceholder from "../../images/avatar_placeholder.svg";
import {
  callEndedSelector,
  chatConnectionSelector,
  chatStateSelector,
} from "../../services/selectors/chat.selector";
import MessageChat from "../message/messageChat";
import useAutosizeTextArea from "../../hooks/useAutosizeTextarea";
import useTypingIndicator from "../../services/hooks/useTypingIndicator";
import ChatNote from "../chatNote/chatNote";
import { useSelector } from "react-redux";
import Modal from "../modals/modal/Modal";
import CallEndedNotificationPopup from "../modals/callEndedNotification/callEndedNotificationPopup";
import { useActions } from "../../services/hooks/useActions";

const Chat = ({
  therapist,
  setTherapist,
  openMenu,
}: {
  therapist: undefined | IUserChatData;
  setTherapist: Dispatch<SetStateAction<undefined | IUserChatData>>;
  openMenu: boolean;
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const mobile = useMediaQuery(mobileWidth);
  const user = useAppSelector(userSelector);
  const connection = useAppSelector(chatConnectionSelector);
  const isConnected = connection?.state === "Connected";
  const chatState = useSelector(chatStateSelector);

  const callEnded = useAppSelector(callEndedSelector);

  const { endCall } = useActions();

  const isAppointmentActive = therapist?.appointment?.appointments?.active;

  const [value, setValue] = useState("");
  const [openSideBar, setOpenSideBar] = useState(true);
  const { isTyping, startTyping } = useTypingIndicator(4000);
  useAutosizeTextArea(textAreaRef.current, value);

  const messages: { date: string; messages: IChatMessage[] }[] = [];

  therapist?.messages?.forEach((m) => {
    const date = m.sentDate.split("T")[0]; // Extract date part only

    // Find if there's already a group for this date
    const existingGroup = messages.find((group) => group.date === date);

    if (existingGroup) {
      // If a group for the date exists, add the message to it
      // @ts-ignore
      existingGroup.messages.push(m);
    } else {
      // Otherwise, create a new group for this date
      // @ts-ignore
      messages.push({ date: date, messages: [m] });
    }
  });

  const handleSendMessage = useCallback(
    (inputValue: string, type: string) => {
      const message = {
        type,
        senderId: Number(user.id),
        content: inputValue,
        receiverId: therapist?.userId,
        appointmentId: therapist?.appointment?.appointments.id,
      };
      connection?.invoke("SendMessage", message);
    },
    [isConnected, connection, therapist, user.id]
  );

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" && event.ctrlKey) {
        event.preventDefault();
        setValue((prev) => `${prev}\n`); // Add a new line to the text
      } else if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSendMessage(value, "Message");
        setValue("");
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleSendMessage, value]);

  useEffect(() => {
    if (isConnected) {
      handleSendMessage(`${TYPING_CODE}:${isTyping ? 1 : 0}`, TYPING_CODE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTyping]);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [therapist]);

  useEffect(() => {
    if (isConnected) {
      handleSendMessage(`${TYPING_CODE}:${isTyping ? 1 : 0}`, TYPING_CODE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTyping]);

  const inputPlaceholder =
    therapist?.appointment?.appointments?.active && isConnected
      ? "כתוב הודעה"
      : "פגישה זו הסתיימה.";

  const content = (
    <div className={chatStyles.container}>
      <div>
        <div className={chatStyles.nameContainer}>
          <img
            src={therapist?.avatar || avatarPlaceholder}
            alt={therapist?.fullName || noDataLabel}
            className={chatStyles.avatar}
          />
          <div className={chatStyles.info}>
            <h1 className={chatStyles.name}>
              {therapist?.fullName || noDataLabel}
            </h1>
            <p className={chatStyles.online}>
              {therapist?.online ? "מחובר" : "לא מחובר"}
            </p>{" "}
            <p className={chatStyles.online}>
              appointment: {therapist?.appointment?.appointments?.id}{" "}
            </p>
          </div>
          {mobile && (
            <button
              className={chatStyles.closeBtn}
              onClick={() => setOpenSideBar(true)}
            />
          )}
        </div>
      </div>
      {messages.length ? (
        <div className={chatStyles.messagesWrapper}>
          {messages.map((m, index) => (
            <div key={index} className={chatStyles.dateSection}>
              <p className={chatStyles.date}>{getDate(m.date)}</p>
              {m.messages?.map((m, ind) => (
                <MessageChat
                  message={m}
                  key={ind}
                  isOwnMessage={m.senderId.toString() === user.id.toString()}
                  therapist={therapist}
                />
              ))}
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
      ) : (
        <div className={`text ${chatStyles.text}`}>אין הודעות עדיין</div>
      )}
      {therapist?.isTyping && (
        <ChatNote name={therapist.fullName} type={chatNoteTypes.isTyping} />
      )}
      {!isConnected && (
        <ChatNote
          name={""}
          type={chatNoteTypes.text}
          text={"את/ה לא מחובר/ת"}
        />
      )}
      {!chatState.myStream && (
        <ChatNote
          name={""}
          type={chatNoteTypes.text}
          text={
            "עליך לאפשר לאפליקציה זו גישה למצלמה ולמיקרופון שלך כדי להשתמש בצ'אט וידאו."
          }
        />
      )}
      <div className={chatStyles.replyBar}>
        <textarea
          className={`${chatStyles.message} ${chatStyles.textArea}`}
          ref={textAreaRef}
          rows={1}
          placeholder={inputPlaceholder}
          onChange={(e) => {
            setValue(e.target.value);
            startTyping();
          }}
          value={value}
          disabled={!isConnected || !isAppointmentActive}
        />
        <button
          className={`${chatStyles.btn} ${chatStyles.sendBtn} ${
            (!isConnected || !value) && chatStyles.sendBtn_disabled
          }`}
          disabled={!isConnected || !value}
          onClick={() => {
            handleSendMessage(value, "Message");
            setValue("");
          }}
        />
      </div>
      {callEnded && (
        <Modal
          onClose={() => {
            endCall(undefined);
            // leaveCall();
          }}
        >
          <CallEndedNotificationPopup
            by={callEnded}
            onClose={() => {
              endCall(undefined);
              // leaveCall();
            }}
          />
        </Modal>
      )}
    </div>
  );
  if (!mobile && !therapist) {
    return <div className={`text ${chatStyles.text}`}>אין הודעות עדיין</div>;
  }

  return (
    <>
      {mobile && openSideBar ? (
        <>
          <ChatSideBar
            setOpenSideBar={setOpenSideBar}
            setTherapist={setTherapist}
            therapist={therapist}
            openMenu={openMenu}
          />
        </>
      ) : (
        content
      )}
    </>
  );
};

export default Chat;

import sideBarSmallStyles from "./sideBarSmall.module.css";
import venchySide from "../../images/venchy_prevencher.png";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import { testUserEmail, testUserEmail2 } from "../../utils/constants";

function SideBarSmall() {
  const navigate = useNavigate();
  const user = useAppSelector(userSelector);
  const isTestUser =
    user?.email.toLowerCase() === testUserEmail ||
    user?.email.toLowerCase() === testUserEmail2;

  return (
    <div className={sideBarSmallStyles.sidebar}>
      <img
        src={venchySide}
        className={sideBarSmallStyles.sidebar__venchy}
        alt={"תמונה של וונצ’י"}
      />
      <ul className={sideBarSmallStyles.sidebar__list}>
        <li
          className={`${sideBarSmallStyles.sidebar__li} ${sideBarSmallStyles.sidebar__li_phone}`}
          onClick={() =>
            navigate("/prevencher", { state: { pathname: "chatGetHelp-phone" } })
          }
          title="טלפון"
        />
        <li
          onClick={() => isTestUser && navigate("/forum")}
          className={`${sideBarSmallStyles.sidebar__li} ${sideBarSmallStyles.sidebar__li_message} ${!isTestUser && sideBarSmallStyles.sidebar__li_disabled}`}
          title="פורום"
        />
        <li
          className={`${sideBarSmallStyles.sidebar__li} ${sideBarSmallStyles.sidebar__li_video}`}
          onClick={() =>
            navigate("/prevencher", { state: { pathname: "chatGetHelp-video" } })
          }
          title="וידאו"
        />
        <li
          className={`${sideBarSmallStyles.sidebar__li} ${sideBarSmallStyles.sidebar__li_chat}`}
          onClick={() =>
            navigate("/prevencher", { state: { pathname: "chatGetHelp-chat" } })
          }
          title="צ'אט"
        />
      </ul>
    </div>
  );
}

export default SideBarSmall;

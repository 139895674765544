import popupStyles from "./updateSubscriptionSuccessPopup.module.css";
import venchyImg from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";

function UpdateSubscriptionPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={popupStyles.popup}>
      <div className={popupStyles.popup__content}>
        <button className={popupStyles.popup__closeBtn} onClick={onClose} />
        <h2 className={popupStyles.popup__title}>השדרוג בוצע בהצלחה!</h2>
        <p className={popupStyles.popup__text}>
          עכשיו אפשר להנות ממגוון עשיר של אפשרויות באפליקציה. רוצה לדעת מה חדש?
        </p>
        <img
          src={venchyImg}
          alt={"תמונה של וונצ’י"}
          className={popupStyles.popup__img}
        />
        <button
          className={`button hover ${popupStyles.popup__nextBtn}`}
          onClick={onClose}
        >
          כן, מה חדש?
        </button>
      </div>
    </article>
  );
}

export default UpdateSubscriptionPopup;

import createForumThemeStyles from "./createForumTheme.module.css";
import React, { ChangeEvent, useState } from "react";
import Modal from "../modals/modal/Modal";
import ForumSuccessPopup from "../modals/forum_success_popup/forum_success_popup";
import { forumCategories } from "../../utils/constants";

const initialFormState = {
  theme: "",
  message: "",
  category: { value: "none", title: "בחר נושא" },
};
function CreateForumTheme({
  setShowCreateNewTheme,
}: {
  setShowCreateNewTheme: (b: boolean) => void;
}) {
  const [form, setForm] = useState(initialFormState);
  const [showThemes, setShowThemes] = useState(false);

  const disabled =
    !form.theme || !form.message || form.category.value === "none";
  const [openModal, setOpenModal] = useState(false);

  function handleChange(
    evt: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const value = evt.target.value;
    setForm({
      ...form,
      [evt.target.name]: value,
    });
  }
  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    setForm(initialFormState);
    setOpenModal(true);
  };

  return (
    <div className={createForumThemeStyles.section}>
      {openModal && (
        <Modal
          onClose={() => {
            setOpenModal(false);
            setShowCreateNewTheme(false);
          }}
        >
          <ForumSuccessPopup
            onClose={() => {
              setOpenModal(false);
              setShowCreateNewTheme(false);
            }}
          />
        </Modal>
      )}
      <h2 className={createForumThemeStyles.title}>יצירת שרשור חדש</h2>
      <form className={createForumThemeStyles.form} onSubmit={submitForm}>
        <label htmlFor={"category"} className={createForumThemeStyles.label}>
          בחירת נושא
        </label>
        <div className={createForumThemeStyles.form__categoriesContainer}>
          <p
            className={`${createForumThemeStyles.input} ${createForumThemeStyles.input_category}`}
            onClick={(e) => {
              e.stopPropagation();
              setShowThemes(true);
            }}
          >
            {form.category.title}
          </p>
          {showThemes && (
            <ul className={createForumThemeStyles.categories}>
              {forumCategories.map(
                (cat, index) =>
                  cat.value !== "date" && (
                    <li
                      className={`${createForumThemeStyles.category} hover`}
                      key={index}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowThemes(false);
                        setForm({ ...form, category: cat });
                      }}
                    >
                      {cat.title}
                    </li>
                  )
              )}
            </ul>
          )}
        </div>
        <label htmlFor={"theme"} className={createForumThemeStyles.label}>
          כותרת השרשורנושא חדש
        </label>
        <input
          type={"text"}
          name={"theme"}
          id={"theme"}
          value={form.theme}
          onChange={handleChange}
          className={createForumThemeStyles.input}
        />
        <label htmlFor="question" className={createForumThemeStyles.label}>
          תוכן הפנייה
        </label>
        <textarea
          value={form.message}
          name={"message"}
          id="message"
          className={createForumThemeStyles.textarea}
          onChange={handleChange}
        />
        <button
          type="submit"
          disabled={disabled}
          className={`${createForumThemeStyles.btn} ${
            disabled ? createForumThemeStyles.btn_disabled : ""
          }`}
        >
          שלח
        </button>
      </form>
    </div>
  );
}

export default CreateForumTheme;

import termsStyles from "./terms.module.css";
import { useState } from "react";
import { useLocation } from "react-router-dom";

function Terms() {
  const [checked, setChecked] = useState(false);

  const location = useLocation();

  const isProfile = location.pathname === "/profile";

  const handleChange = () => {
    setChecked(!checked);
  };
  return (
    <section
      className={`${termsStyles.section} ${
        !isProfile && termsStyles.section_type_terms
      }`}
    >
      {isProfile && (
        <h2 className={termsStyles.section__title}>תקנון ומדיניות פרטיות</h2>
      )}
      <div className={termsStyles.section__container}>
        <div
          className={`${termsStyles.scrollable} ${
            !isProfile && termsStyles.scrollable_not
          }`}
        >
          <p className={termsStyles.section__text}>
            שימושך באתר הינו בכפוף לתנאי השימוש ומדיניות הפרטיות ולתקנון האתר.
          </p>
          <p className={termsStyles.section__text}>
            גישה רק לדברים החיוניים ביותר. שדרג לפרימיום וקבל שירותי תמיכה נפשית
            על ידי פסיכולוג מוסמך זמין 24/7, התייעצות בפורום עם מתמודדים כמוך,
            ועודגישה רק לדברים גישה רק לדברים החיוניים ביותר.
          </p>
          <p className={termsStyles.section__text}>
            מסמך זה מרכז עבורך את תנאי השימוש ואת מדיניות הפרטיות באתר (להלן יחד
            "המדיניות"). על מנת להציג ולהסביר את נוהגי החברה ביחס לפרטיות
            המשתמשים באתר, וכיצד משתמשת החברה במידע, הנמסר לה על-ידי המשתמשים
            באתר או הנאסף על-ידה בעת השימוש באתר.
          </p>
          <p className={termsStyles.section__text}>
            החברה שומרת לעצמה את הזכות לשנות, למחוק ו/או להוסיף על תנאי מתנאי
            המדיניות בכל עת וללא התחייבות למתן הודעה מוקדמת או בדיעבד, לכן מומלץ
            להתעדכן מעת לעת במדיניות הנהוגה. ככל ואינך מסכים לתנאי המדיניות,
            כולם או חלקם, אנא הימנע משימוש באתר.
          </p>
          <p className={termsStyles.section__text}>
            קישורים שעשויים להיות מוצגים באתר זה, לאתרים חיצוניים או אחרים,
            מוצגים לנוחיותך בלבד. כל הסתמכות על אתרים אלו או על תוכנם, שימוש בהם
            או מסירת פרטים דרכם, הינה באחריות המשתמש בלבד ובהתאם לתנאי השימוש,
            תקנון, ומדיניות הפרטיות של אותם אתרים, בנפרד ובאופן בלתי תלוי מתנאי
            מדיניות אלו.
          </p>
          <p className={termsStyles.section__text}>
            יובהר כי, החברה שומרת לעצמה את הזכות להסיר מהאתר בכל עת קישורים
            המופיעים בו, או להימנע מהוספת קישורים חדשים, הכל לפי שיקול דעתה
            המוחלט.
          </p>
        </div>
        {isProfile && (
          <>
            <label htmlFor={"terms"} className={termsStyles.label}>
              <input
                type={"checkbox"}
                name={"terms"}
                id={"terms"}
                checked={checked}
                onChange={handleChange}
                className={termsStyles.input}
              />
              מסכים לתקנון ומדיניות פרטיות
            </label>
            <button
              type={"button"}
              onClick={handleChange}
              className={`button hover ${termsStyles.btn}`}
            >
              קראתי ואני מאשר
            </button>
          </>
        )}
      </div>
    </section>
  );
}

export default Terms;

import React, { useEffect, useState } from "react";
import { SingleValue, components } from "react-select";
import { IAppointment, IOption, IPopupWithoutProps } from "../../../types/types";
import { useLazyGetTherapistDatesQuery } from "../../../services/api/therapists.api";
import { useNavigate } from "react-router-dom";
import Modal from "../modal/Modal";
import AppointmentSuccessPopup from "../appointment_success_popup/appointment_success_popup";
import { appointmentOptions } from "../../../utils/constants";
import { useCancelAppointmentMutation, useGetAllCancelAppointmentsReasonsMutation, useLazyGetAppointmentsByUserIdQuery } from "../../../services/api/user.api";
import CloseButton from "../../closeButton/closeButton";
import Select from "react-select";
import popupStyles from "./cancelAppointmentConfirmation.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { useAppSelector } from "../../../services/hooks";
import { cancelAppointmentReasonsSelector, userSelector } from "../../../services/selectors/user.selectors";

interface CancelAppointmentConfirmationPopupProps extends IPopupWithoutProps {
  appointmentData: IAppointment;
}

function CancelAppointmentConfirmationPopup({
  onClose,
  appointmentData,
}: CancelAppointmentConfirmationPopupProps) {
  const navigate = useNavigate();

  const user = useAppSelector(userSelector);
  const cancleAppointmentReasons = useAppSelector(cancelAppointmentReasonsSelector);

  const mappedOptions = cancleAppointmentReasons?.map((option) => ({
    value: option.isAnother ? "other" : option.id.toString(),
    label: option.reason,
    isCustom: option.isAnother,
  }));

  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const [fetchDates] = useLazyGetTherapistDatesQuery();
  const [cancelAppointment] = useCancelAppointmentMutation();
  const [getAllcancelAppointmentsReasons] = useGetAllCancelAppointmentsReasonsMutation();
  const [getallappointments] = useLazyGetAppointmentsByUserIdQuery()

  useEffect(() => {
    getAllcancelAppointmentsReasons({ companyId: user.companyId, appointment: { AnotherReason: "", Id: 0 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleInputChange = (inputValue: string) => {
    if (selectedOption?.isCustom) {
      setSelectedOption({ value: inputValue, label: inputValue, isCustom: true });
    }
  };

  const handleChange = (selected: SingleValue<IOption>) => {
    if (selected?.value === "other") {
      setSelectedOption({ value: "", label: "הכנס את הסיבה", isCustom: true });
    } else {
      setSelectedOption(selected);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      (e.target as HTMLInputElement).blur();
    }
  };

  const customClassNames = {
    control: () => `button hover ${popupStyles.popup__btn} ${popupStyles.popup__btn_type_change} ${popupStyles.select_control}`,
    menu: () => popupStyles.select_menu,
    option: (state: any) =>
      state.isSelected
        ? popupStyles.option_selected
        : state.isFocused
          ? popupStyles.option_focused
          : popupStyles.option,
    singleValue: () => popupStyles.single_value,
  };

  return openModal ? (
    <Modal
      onClose={() => {
        onClose();
        setOpenModal(false);
      }}
    >
      <AppointmentSuccessPopup
        type={appointmentOptions.Cancel}
        onClose={() => {
          onClose();
          setOpenModal(false);
        }}
      />
    </Modal>
  ) : (
    <article className={popupStyles.popup}>
      <CloseButton onClick={onClose} />
      <h2 className={popupStyles.popup__title}>
        האם אתה בטוח שברצונך לבטל את הפגישה?
      </h2>
      <p className={popupStyles.popup__text}>עם ביטול הפגישה, לא תוכל לבטל את הפעולה.</p>
      <p className={popupStyles.popup__text}>הודעה תישלח למטפל שלך על הביטול</p>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={popupStyles.popup__img}
      />
      <Select
        classNames={customClassNames}
        options={mappedOptions}
        name={"cancelReason"}
        value={selectedOption}
        placeholder={"*בחר את סיבת הביטול..."}
        onChange={handleChange}
        components={{
          Input: (props: any) => {
            const isCustom = props.selectProps.value?.isCustom;
            return (
              <components.Input
                {...props}
                autoFocus={isCustom} // Focus the input when "אחר" is selected
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.selectProps.onInputChange(e.target.value, { action: "input-change" }); // Use selectProps to access onInputChange
                  handleInputChange(e.target.value);
                }}
                onKeyDown={handleKeyDown} // Unfocus input on Enter key press
              />
            );
          },
        }}
      />
      <button
        disabled={true}
        type="button"
        className={`button hover ${popupStyles.popup__btn} ${popupStyles.popup__btn_type_change} ${popupStyles.popup__btn_disabled}`}
        onClick={() => {
          fetchDates(appointmentData.appointments.therapistsId.toString());
          navigate("search", {
            replace: true,
            state: { appointmentData },
          });
        }}
      >
        לשנות את מועד הפגישה
      </button>
      <button
        type="button"
        className={`button hover ${popupStyles.popup__btn} ${selectedOption == null && popupStyles.popup__btn_disabled}`}
        onClick={() => {
          if (selectedOption != null) {
            cancelAppointment({
              companyId: appointmentData.appointments.companyId, appointment: {
                Id: 0,
                CancelAppReasonsId: selectedOption.isCustom ? cancleAppointmentReasons?.find(item => item.isAnother)?.id ?? 0 : Number(selectedOption.value),
                AppointmentsId: appointmentData.appointments.id,
                CompanyId: appointmentData.appointments.companyId,
                AnotherReason: selectedOption.isCustom ? selectedOption.value : "",
                CreatedUsersId: appointmentData.appointments.usersId,
                Timestamp: new Date().toISOString()
              }
            }).then(res => {
              if ("data" in res) {
                setOpenModal(true);
                getallappointments({ companyId: user.companyId, guid: user.guid })
              }
            });
          }
        }}
      >
        לבטל את הפגישה
      </button>
    </article>
  );
}

export default CancelAppointmentConfirmationPopup;

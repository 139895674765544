import prevencherAnswerStyles from "./prevencherAnswer.module.css";
import { IContactUsTheme } from "../../utils/categories";
import prevencherAvatar from "../../images/questionnaire_doctor.png";
import React, { useState } from "react";
import Modal from "../modals/modal/Modal";
import PrevencherAnswerPopup from "../modals/prevencherAnswer_popup/prevencherAnswer_popup";

function PrevencherAnswer({ theme }: { theme: IContactUsTheme }) {
  const [openModal, setOpenModal] = useState(false);
  const onClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpenModal(false);
  };
  return (
    <li
      className={prevencherAnswerStyles.answer}
      onClick={() => setOpenModal(true)}
    >
      <h3 className={prevencherAnswerStyles.answer__title}>{theme.title}</h3>
      <div className={prevencherAnswerStyles.prevencher}>
        <img
          className={prevencherAnswerStyles.prevencher__avatar}
          src={prevencherAvatar}
          alt={"פריוונצ’ר"}
        />
        <p className={prevencherAnswerStyles.prevencher__name}>פריוונצ’ר</p>
      </div>
      <p className={prevencherAnswerStyles.answer__text}>{theme.text}</p>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <PrevencherAnswerPopup onClose={onClose} theme={theme} />
        </Modal>
      )}
    </li>
  );
}

export default PrevencherAnswer;

import mainContentLayoutStyles from "../../components/mainContentLayout/mainContentLayout.module.css";
import ChatSideBar from "../../components/chatSidebar/chatSideBar";
import { mobileWidth } from "../../utils/constants";
import Sidebar from "../../components/sidebar/sidebar";
import React, { useEffect, useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import Chat from "../../components/chat/chat";
import { Category, IUserChatData } from "../../types/types";
import Notifications from "../../components/notifications/notifications";
import { menuTitles } from "../../utils/categories";
import { useAppSelector } from "../../services/hooks";
import {
  callEndedSelector,
  chatConnectionSelector,
  chatUsersSelector,
} from "../../services/selectors/chat.selector";
import Modal from "../../components/modals/modal/Modal";
import Chat_popup from "../../components/modals/chat_popup/chat_popup";
import { useActions } from "../../services/hooks/useActions";
import { useLazyGetAppointmentsByUserIdQuery } from "../../services/api/user.api";
import { userSelector } from "../../services/selectors/user.selectors";
import { videoCallInitialState } from "../../services/slices/chat.slice";

interface IChatPageProps {
  activeCategory: Category;
  changeCategory: (e: React.MouseEvent, cat: Category) => void;
}

function ChatPage({ activeCategory, changeCategory }: IChatPageProps) {
  const user = useAppSelector(userSelector);
  const chatUsers = useAppSelector(chatUsersSelector);
  const connection = useAppSelector(chatConnectionSelector);
  const callEnded = useAppSelector(callEndedSelector);
  const { setCallAccepted, setCall, setStartCall, setCallEnded } = useActions();

  const [therapist, setTherapist] = useState<IUserChatData | undefined>(
    undefined
  );
  const [openMenu, setOpenMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const mobile = useMediaQuery(mobileWidth);

  const [getAppointmentsByUserId] = useLazyGetAppointmentsByUserIdQuery();

  useEffect(() => {
    if (connection && chatUsers.length > 0) {
      connection
        .invoke(
          "FetchChatDataForUsers",
          chatUsers.map((u) => u.userId)
        )
        .catch((err) =>
          console.error("Error calling FetchChatDataForUsers:", err)
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection]);

  useEffect(() => {
    if (!therapist?.userId) {
      setTherapist(
        chatUsers.find(
          (u) => u.appointment && u.appointment.appointments.active
        )
      );
    } else {
      setTherapist((prev) => chatUsers.find((u) => u.userId === prev?.userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatUsers]);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const hideNotifications = () => {
    if (showNotifications) setShowNotifications(false);
  };
  const endCall = () => {
    setCall(videoCallInitialState);
    setCallAccepted(false);
    setStartCall(false);
    setCallEnded(undefined);

    if (user.guid) {
      getAppointmentsByUserId({
        companyId: user.companyId,
        guid: user.guid,
      });
    }
  };
  return (
    <main
      className={`${mainContentLayoutStyles.main} ${
        mainContentLayoutStyles.main_chat
      } ${!openMenu && mainContentLayoutStyles.main_chat_small}`}
    >
      <Notifications showNotifications={showNotifications} />
      <div
        className={`${mainContentLayoutStyles.overlay} ${
          mobile && openMenu ? mainContentLayoutStyles.overlay_visible : ""
        }`}
      />
      <section
        className={`${mainContentLayoutStyles.mainContent} ${mainContentLayoutStyles.mainContent_chat}`}
        onClick={hideNotifications}
      >
        <div
          className={`${mainContentLayoutStyles.header} ${mainContentLayoutStyles.header_chat}`}
        >
          <h1 className={`${mainContentLayoutStyles.mainContent__title}`}>
            {menuTitles[activeCategory]}
          </h1>
          <button
            className={`${mainContentLayoutStyles.mainContent__bell} ${
              showNotifications &&
              mainContentLayoutStyles.mainContent__bell_invisible
            }`}
            onClick={() => {
              setShowNotifications(!showNotifications);
            }}
          />
          <button
            type="button"
            onClick={toggleMenu}
            className={mainContentLayoutStyles.burger}
          />
        </div>
        <Chat
          setTherapist={setTherapist}
          therapist={therapist}
          openMenu={openMenu}
        />
      </section>
      {!mobile && (
        <ChatSideBar
          setTherapist={setTherapist}
          therapist={therapist}
          openMenu={openMenu}
        />
      )}
      <Sidebar
        changeCategory={changeCategory}
        activeCategory={activeCategory}
        openMenu={openMenu}
        toggleMenu={toggleMenu}
      />
      {callEnded && (
        <Modal onClose={endCall}>
          <Chat_popup
            onClose={endCall}
            text={`הפגישה הסתיימה על ידי ${callEnded}`}
          />
        </Modal>
      )}
    </main>
  );
}

export default ChatPage;

import popupStyles from "./signup_success_popup.module.css";
import { mobileWidth } from "../../../utils/constants";
import { IPopupWithoutProps } from "../../../types/types";
import prevenchik from "../../../images/onboarding/pic1.png";
import { testData } from "../../../utils/user";
import CloseButton from "../../closeButton/closeButton";

function SignupSuccessPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={popupStyles.popup}>
      <CloseButton onClick={onClose} />
      <h2 className={popupStyles.popup__title}>ברוך הבא!</h2>
      <img className={popupStyles.popup__img} src={prevenchik} alt={"וונצ’י"} />
      <p className={popupStyles.popup__text}>המערכת יצרה עבורך מספר משתמש</p>
      <p className={popupStyles.popup__account}>{testData.account}</p>
      <p className={popupStyles.popup__text}>
        שמור מספר זה, הוא ישרת אותך להזדהות בפריוונצר או כמשתמש אנונימי
      </p>
      <button
        className={`button ${popupStyles.popup__nextBtn} hover`}
        onClick={onClose}
      >
        אני רוצה להתחיל!
      </button>
    </article>
  );
}

export default SignupSuccessPopup;

import sidebarStyles from "./sidebar.module.css";
import { Category } from "../../types/types";
import React, { FC } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../services/hooks";
import avatarPlaceholder from "../../images/avatar_placeholder_purple.svg";
import { mobileWidth, testUserEmail, testUserEmail2 } from "../../utils/constants";
import { userSelector } from "../../services/selectors/user.selectors";
import { useActions } from "../../services/hooks/useActions";

export interface ISidebar {
  changeCategory: (
    e:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    cat: Category
  ) => void;
  activeCategory: Category;
  toggleMenu: () => void;
  openMenu: boolean;
}
const Sidebar: FC<ISidebar> = ({
  changeCategory,
  activeCategory,
  toggleMenu,
  openMenu,
}) => {
  const mobile = useMediaQuery(mobileWidth);
  const location = useLocation();
  const navigate = useNavigate();
  const isChat = location.pathname === "/chat";
  const user = useAppSelector(userSelector);

  const isTestUser =
    user?.email.toLowerCase() === testUserEmail ||
    user?.email.toLowerCase() === testUserEmail2;


  const { logout } = useActions();

  return (
    <section
      className={`${sidebarStyles.sidebar} ${isChat && !mobile && sidebarStyles.sidebar_chat
        } ${mobile && openMenu ? sidebarStyles.sidebar_open : ""}`}
      onClick={toggleMenu}
    >
      <div
        className={`${sidebarStyles.sidebar__content_visible} ${openMenu && !mobile
          ? ""
          : mobile
            ? sidebarStyles.sidebar_open
            : sidebarStyles.sidebar__content_small
          }`}
      >
        <button className={sidebarStyles.sidebar__arrow} />
        <div className={sidebarStyles.sidebar__logo} />
        <div className={sidebarStyles.sidebar__welcome}>
          <h2 className={sidebarStyles.sidebar__title}>
            היי {user.firstName ? user.firstName : "אורח"}
          </h2>
          <img
            onClick={(e) => {
              !mobile && e.stopPropagation();
              navigate("/profile", {
                replace: true,
                state: { pathname: "profile" },
              });
            }}
            className={sidebarStyles.sidebar__avatar}
            src={user.avatar ? user.avatar : avatarPlaceholder}
            alt="תמונת משתמש"
          />
        </div>
        <div className={sidebarStyles.scrollable}>
          <ul className={sidebarStyles.sidebar__pages}>
            <li
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Day
                ? sidebarStyles.sidebar__page_active
                : ""
                }`}
              onClick={(e) => changeCategory(e, Category.Day)}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>היום שלי</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_myDay}`}
              />
            </li>
            <li
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Treatment
                ? sidebarStyles.sidebar__page_active
                : ""
                } `}
              onClick={(e) => changeCategory(e, Category.Treatment)}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>הטיפול שלי</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_treatment}`}
              />
            </li>
            <li
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Help
                ? sidebarStyles.sidebar__page_active
                : ""
                }`}
              onClick={(e) => changeCategory(e, Category.Help)}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>קבלת עזרה</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_support}`}
              />
            </li>
            <li
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Goals
                ? sidebarStyles.sidebar__page_active
                : ""
                } ${!isTestUser && sidebarStyles.sidebar__page_disabled}`}
              onClick={(e) => isTestUser && changeCategory(e, Category.Goals)}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>תובנות ומטרות</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_goals}`}
              />
            </li>
            <li
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Profile
                ? sidebarStyles.sidebar__page_active
                : ""
                }`}
              onClick={(e) => changeCategory(e, Category.Profile)}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>הפרופיל שלי</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_profile}`}
              />
            </li>
            <li>
              <Link
                to={"/chat"}
                className={`${sidebarStyles.sidebar__page} 
                ${sidebarStyles.sidebar__page_messages}
                ${activeCategory === Category.Messages
                    ? sidebarStyles.sidebar__page_active
                    : ""
                  }`}
              >
                {user.unreadMessages > 0 && (
                  <div className={sidebarStyles.sidebar__messages}>
                    {user.unreadMessages}
                  </div>
                )}
                <p className={sidebarStyles.sidebar__pageTitle}>הודעות</p>
                <div
                  className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_messages}`}
                />
              </Link>
            </li>
            <Link
              to={"/prevencher"}
              className={`${sidebarStyles.sidebar__page} ${activeCategory === Category.Prevencher
                ? sidebarStyles.sidebar__page_active
                : ""
                }`}
              onClick={(e) => changeCategory(e, Category.Prevencher)}
            >
              <p className={sidebarStyles.sidebar__pageTitle}>פריוונצ’ר</p>
              <div
                className={`${sidebarStyles.sidebar__pageIcon} ${sidebarStyles.sidebar__pageIcon_prevencher}`}
              />
            </Link>
          </ul>
          <ul className={sidebarStyles.sidebar__footer}>
            <li
              onClick={(e) => {
                changeCategory(e, Category.Contacts);
              }}
            >
              <div
                className={`${sidebarStyles.sidebar__footerItem} ${activeCategory === Category.Contacts &&
                  sidebarStyles.sidebar__page_active
                  }`}
              >
                <p className={sidebarStyles.sidebar__footerTitle}>צור קשר</p>
                <div
                  className={`${sidebarStyles.sidebar__footerIcon} ${sidebarStyles.sidebar__footerIcon_contacts}`}
                />
              </div>
            </li>
            <li
              onClick={(e) => {
                changeCategory(e, Category.Terms);
                navigate("/terms", {
                  replace: true,
                });
              }}
            >
              <div
                className={`${sidebarStyles.sidebar__footerItem} ${activeCategory === Category.Terms &&
                  sidebarStyles.sidebar__page_active
                  }`}
              >
                <p className={sidebarStyles.sidebar__footerTitle}>
                  מדיניות ופרטיות
                </p>
                <div
                  className={`${sidebarStyles.sidebar__footerIcon} ${sidebarStyles.sidebar__footerIcon_conditions}`}
                />
              </div>
            </li>
            <li>
              <div
                className={sidebarStyles.sidebar__footerItem}
                onClick={(e) => {
                  e.stopPropagation();
                  logout();
                  navigate("/login");
                }}
              >
                <p className={sidebarStyles.sidebar__footerTitle}>יציאה</p>
                <div
                  className={`${sidebarStyles.sidebar__footerIcon} ${sidebarStyles.sidebar__footerIcon_logout}`}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Sidebar;

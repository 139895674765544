import myPracticeStyles from "./myPractice.module.css";
import { useState } from "react";
import { videoExample } from "../../utils/constants";
import { IVideo } from "../../types/types";
import { useLocation, useNavigate } from "react-router-dom";
import { userSelector } from "../../services/selectors/user.selectors";
import { useAppSelector } from "../../services/hooks";

function MyPractice() {
  const location = useLocation();
  const navigate = useNavigate();
  const [video, setVideo] = useState<IVideo>(videoExample[0]);

  const user = useAppSelector(userSelector);
  const wishPlan = user.planId === 1 ? "פרימיום" : "פרימיום פלוס";

  return (
    <section className={myPracticeStyles.section}>
      <h2 className={myPracticeStyles.section__title}>התרגילים שלי</h2>
      <p className={myPracticeStyles.section__text}>התרגיל שלך להיום</p>
      <article className={myPracticeStyles.practice}>
        <div className={myPracticeStyles.content}>
          <video
            className={myPracticeStyles.video}
            src={video.link}
            controls={true}
          />
          <div className={myPracticeStyles.info}>
            <h3 className={myPracticeStyles.videoTitle}>{video.title}</h3>
            <p className={myPracticeStyles.videoAbout}>{video.about}</p>
          </div>
        </div>
        {user.planId < 3 && (
          <div className={myPracticeStyles.bottomBar}>
            <p className={myPracticeStyles.text_purple}>
              שדרג למנוי {wishPlan} וקבל את מלוא התכנים
            </p>
            <button
              className={`button ${myPracticeStyles.planBtn} hover`}
              onClick={() => {
                navigate("/profile", { replace: true, state: location });
              }}
            >
              שדרג ל{wishPlan}
            </button>
          </div>
        )}
      </article>
      <article className={myPracticeStyles.practice}>
        <h4 className={myPracticeStyles.practice__sectionTitle}>
          תרגולים נוספים
        </h4>
        <ul className={myPracticeStyles.practice__swiper}>
          <li>
            <p className={myPracticeStyles.swiper__text}>
              תרגול מיינדפולנס מס’ 1
            </p>
            <video
              src="https://media.istockphoto.com/id/1192719321/video/calm-young-african-man-meditate-on-sofa-with-eyes-closed.mp4?s=mp4-640x640-is&k=20&c=uV8ZVhrWA_yrurfcMILSQx17M1pU1j_59PqMRkzSVy8="
              className={myPracticeStyles.video}
              controls
            />
          </li>
          <li>
            <p className={myPracticeStyles.swiper__text}>
              תרגול מיינדפולנס מס’ 2
            </p>
            <video
              src="https://media.istockphoto.com/id/1347499462/video/rock-balancing-on-ocean-beach-stones-stacking-by-sea-water-waves-pyramid-of-pebbles-on-sandy.mp4?s=mp4-640x640-is&k=20&c=_hgJWxzHdWYXcrIGKCbGl-RmxcMG3XnaxRL_iJyE6PE="
              className={myPracticeStyles.video}
              controls
            />
          </li>
        </ul>
      </article>
    </section>
  );
}

export default MyPractice;

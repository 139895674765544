import messageStyles from "./message.module.css";
import { getTimeFromTimestamp } from "../../utils/utils";
import { IChatMessage, IUserChatData } from "../../types/types";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import { noDataLabel } from "../../utils/constants";
import avatarPlaceholder from "../../images/avatar_placeholder.svg";

function MessageChat({
  message,
  isOwnMessage,
  therapist,
}: {
  message: IChatMessage;
  isOwnMessage: boolean;
  therapist: IUserChatData | undefined;
}) {
  const messageClassname = isOwnMessage
    ? `${messageStyles.message} ${messageStyles.ownMessage}`
    : messageStyles.message;
  const containerClassname = isOwnMessage
    ? messageStyles.ownMessageContainer
    : messageStyles.messageContainer;

  const user = useAppSelector(userSelector);

  const avatar = isOwnMessage ? user.avatar : therapist?.avatar;
  const alt = isOwnMessage ? user.fullName : noDataLabel;

  return (
    <>
      <div className={containerClassname}>
        <img
          src={avatar || avatarPlaceholder}
          alt={alt}
          className={messageStyles.avatar}
        />
        <div className={messageClassname}>
          <p className={messageStyles.text}>
            {message.content.split("\n").map((part, index) => (
              <span key={index} className={messageStyles.textPart}>
                {part}
              </span>
            ))}
          </p>
          <p
            className={`${messageStyles.time} ${
              isOwnMessage && messageStyles.time_ownMessage
            }`}
          >
            {message.sentDate ? getTimeFromTimestamp(message.sentDate) : ""}
          </p>
        </div>
      </div>
    </>
  );
}

export default MessageChat;

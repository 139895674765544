import OnboardingLayout from "../../components/onboardingLayout/onboardingLayout";
import singinStyles from "./signin.module.css";
import privateAccountImg from "../../images/private_account_icon.svg";
import corporateAccountImg from "../../images/corporate_account_icon.svg";
import medicalAccountImg from "../../images/medical_account_icon.svg";
import Urgent from "../../components/urgent/urgent";
import { Link, useNavigate } from "react-router-dom";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";

function SigninPage() {
  const navigate = useNavigate();

  return (
    <OnboardingLayout>
      <article className={singinStyles.signin}>
        <BackArrowButton onClick={() => navigate("/")} />
        <h1 className={singinStyles.signin__title}>הרשמה</h1>
        <p className={singinStyles.signin__text}>דרך איזה חשבון תרצה להירשם?</p>
        <ul className={singinStyles.signin__list}>
          <Link
            to="/signin/private"
            className={`${singinStyles.signin__listItem} hover`}
          >
            <img
              src={privateAccountImg}
              alt="חשבון פרטי סמל"
              className={singinStyles.signin__icon}
            />
            <p className={singinStyles.signin__listText}>חשבון פרטי</p>
          </Link>
          <li className={`${singinStyles.signin__listItem} hover`}>
            <img
              src={corporateAccountImg}
              alt="חשבון ארגוני סמל"
              className={singinStyles.signin__icon}
            />
            <p className={singinStyles.signin__listText}>חשבון ארגוני</p>
          </li>
          <li className={`${singinStyles.signin__listItem} hover`}>
            <img
              src={medicalAccountImg}
              alt="חשבון מרפאה סמל"
              className={singinStyles.signin__icon}
            />
            <p className={singinStyles.signin__listText}>חשבון מרפאה</p>
          </li>
        </ul>
        <div className={singinStyles.signin__conditions}>
          <p className={singinStyles.signin__terms}>
            על ידי הקשה על המשך, אתה מסכים
          </p>
          <Link to="/terms" className={singinStyles.signin__link}>
            לתנאים
          </Link>
          <p className={singinStyles.signin__terms}>שלנו ומאשר שקראת את</p>
          <Link to="/terms" className={singinStyles.signin__link}>
            מדיניות הפרטיות
          </Link>
          <p className={singinStyles.signin__terms}>
            שלנו, שמסבירה כיצד לבטל את הסכמתך להצעות ומבצעים
          </p>
        </div>
        <Urgent />
      </article>
    </OnboardingLayout>
  );
}

export default SigninPage;

import { IAppointment, IUpdate, IUser } from "../../types/types";
import {
  defaultSettings,
  existingUserFlowId,
} from "../../utils/constants";
import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "../api/auth.api";
import { RootState } from "../types";
import {
  IAllflowUser,
  IUserAuth,
  TExercise,
  TQuestionairResponse,
} from "../types/auth.types";
import { deleteCookie, getCookie, setCookie } from "../../utils/token";
import { userApi } from "../api/user.api";
import { videoExamples } from "../../utils/videoExamples";
import { utilsApi } from "../api/utils.api";
import { TCancelAppointmentReason, TGetQuestionByIdResponse } from "../types/user.types";
import { prevencherId } from "../../utils/prevencherForGuestsQuestions";

const accessToken = getCookie("accessToken");
const refreshToken = getCookie("refreshToken");

const initialUser: IUser = {
  passport: "",
  roleId: 0,
  statusId: 0,
  id: "",
  guid: "",
  firstName: "",
  lastName: "",
  fullName: "",
  account: "",
  avatar: "",
  email: "",
  birth: new Date(),
  gender: 0,
  planId: 1,
  password: "",
  phone: "",
  address: "",
  plan: "",
  messages: [],
  updates: [],
  therapists: [],
  mockAppointments: [],
  settings: defaultSettings,
  prescriptions: [],
  invoices: [],
  unreadMessages: 0,
  videos: videoExamples,
  exercises: [],
};

const initialState: IUserAuth = {
  user: initialUser,
  accessToken: accessToken,
  refreshToken: refreshToken,
  error: null,
  isAuth: false,
  currentUserFlowId: 0,
  nextStep: null,
  nextQuestionId: -1,
  currentQuestionnaireId: -1,
  currentQuestion: null,
  currentQuestionId: -1,
  isEnd: false,
  questionsOptions: [],
  endQuestion: "",
  startQuestion: "היי , אני תמיד כאן בשבילך",
  prevencherChatMessages: [],
  prevencherPOCMessages: [],
  allAnswers: [],
  allQuestions: [],
  currentQuestionnaireCompanies: null,
  companyId: 0,
  cancelAppointmentReasons: []
};

const storeUser = (state: IUserAuth, { payload }: any) => {
  for (let key in payload.user) {
    state.user[key.charAt(0).toLowerCase() + key.slice(1)] = payload.user[key];
  }
  state.currentUserFlowId = existingUserFlowId;
};

const storeTokens = (state: IUserAuth, { payload }: any) => {
  const { accessToken, refreshToken, user } = payload;
  if (!accessToken) {
    return;
  }
  state.accessToken = accessToken;
  state.refreshToken = refreshToken;
  if (user) {
    storeUser(state, { payload });
  }
  state.error = payload.success ? null : payload.message;
  state.isAuth = payload.success ? payload.success : false;
  if (state.accessToken) {
    setCookie("accessToken", state.accessToken, { expires: 5000 });
  }
  if (state.refreshToken) {
    setCookie("refreshToken", state.refreshToken);
  }
};

const storeErrors = (state: IUserAuth, { payload }: any) => {
  const { data } = payload;
  const error =
    typeof data?.message === "string"
      ? data.message
      : "משהו השתבש, אנא נסה שוב מאוחר יותר";
  return { ...state, error };
};
const storeAllflowUser = (
  state: IUserAuth,
  { payload }: { payload: IAllflowUser[] }
) => {
  return { ...state, allUserFlows: payload };
};

export const authUserSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    setPhone: (state, { payload }) => {
      return { ...state, user: { ...state.user, phone: payload } };
    },
    logout: () => {
      deleteCookie("accessToken");
      return { ...initialState };
    },
    setAppointment: (state, { payload }) => {
      const { therapist, date } = payload;
      const newAppointment = {
        id: state.user.mockAppointments.length,
        therapist,
        date,
      };
      return {
        ...state,
        user: {
          ...state.user,
          mockAppointments: [...state.user.mockAppointments, newAppointment],
        },
      };
    },
    updateStateAppointment: (state, { payload }) => {
      const appointment = state.user.mockAppointments.find(
        (a) => a.id === payload.appointmentId
      );
      if (appointment) appointment.date = payload.date;
    },
    
    setTherapist: (state, { payload }) => {
      const { therapist } = payload;
      const isMyTherapist = state.user.therapists.find(
        (obj) => obj.id === therapist.id
      );
      if (!isMyTherapist)
        return {
          ...state,
          user: {
            ...state.user,
            therapists: [...state.user.therapists, therapist],
          },
        };
    },
    setNextQuestion: (state: IUserAuth) => {
      state.nextQuestionId = 1;
    },
    setUserFlowId: (state: IUserAuth, { payload }) => {
      state.currentUserFlowId = payload;
    },
    setMessages: (state: IUserAuth, { payload }) => {
      return {
        ...state,
        prevencherChatMessages: [...state.prevencherChatMessages, payload],
        nextQuestionId: payload.nextQuestionId,
      };
    },
    setQuestionOptions: (state: IUserAuth, { payload }) => {
      return {
        ...state,
        questionsOptions: payload,
      };
    },
    setCurrentQuestion: (state: IUserAuth, { payload }) => {
      if (
        state.prevencherChatMessages.at(-1)?.message != payload?.Questions?.Desc
      ) {
        return {
          ...state,
          currentQuestion: payload.Questions,
          prevencherChatMessages: [
            ...state.prevencherChatMessages,
            {
              messageId: payload.Questions.Id.toString(),
              id: prevencherId,
              date: new Date(),
              unread: false,
              message: payload.Questions.Desc,
              nextQuestionId: state.nextQuestionId,
            },
          ],
          questionsOptions: payload.QuestionsOptions || [],
        };
      }
    },
    setPOCMessages: (state: IUserAuth, { payload }) => {
      return {
        ...state,
        prevencherPOCMessages: [...state.prevencherPOCMessages, payload],
        nextQuestionId: payload.nextQuestionId,
      };
    },
    setOptions: (state: IUserAuth, { payload }) => {
      return {
        ...state,
        questionsOptions: payload,
      };
    },
    setAnswers: (state: IUserAuth, { payload }) => {
      const newAnswer = { ...payload, AnswerDate: payload.AnswerDate ?? null };
      return {
        ...state,
        allAnswers: [...state.allAnswers, newAnswer],
      };
    },
    resetQuestionnair: (state: IUserAuth) => {
      return {
        ...state,
        currentQuestionnaireId: -1,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.login.matchFulfilled, storeTokens)
      .addMatcher(authApi.endpoints.login.matchRejected, storeErrors)
      .addMatcher(authApi.endpoints.loginGoogle.matchFulfilled, storeTokens)
      .addMatcher(authApi.endpoints.loginGoogle.matchRejected, storeErrors)
      .addMatcher(authApi.endpoints.loginWithCode.matchFulfilled, () => {})
      .addMatcher(authApi.endpoints.loginWithCode.matchRejected, storeErrors)
      .addMatcher(authApi.endpoints.register.matchFulfilled, storeTokens)
      // .addMatcher(authApi.endpoints.setUserPassword.matchFulfilled, storeTokens)
      .addMatcher(authApi.endpoints.register.matchRejected, storeErrors)
      .addMatcher(authApi.endpoints.sendCode.matchFulfilled, storeTokens)
      .addMatcher(authApi.endpoints.sendCode.matchRejected, storeErrors)
      .addMatcher(userApi.endpoints.getUser.matchFulfilled, (state, action) => {
        storeUser(state, action);
        state.error = action.payload.success ? null : action.payload.message;
        state.isAuth = action.payload.success;
      })
      .addMatcher(
        utilsApi.endpoints.getAllflowUser.matchFulfilled,
        storeAllflowUser
      )
      .addMatcher(
        userApi.endpoints.getQuestionByQuestionnaireId.matchFulfilled,
        (
          state: IUserAuth,
          { payload }: { payload: TGetQuestionByIdResponse }
        ) => {
          state.isEnd = payload.Questions?.IsEnd;
          state.currentQuestion = payload.Questions;
          state.currentQuestionId = payload.Questions?.Id;
          state.questionsOptions = payload?.QuestionsOptions || [];
        }
      )
      .addMatcher(
        userApi.endpoints.getPOCQuestionById.matchFulfilled,
        (
          state: IUserAuth,
          { payload }: { payload: TGetQuestionByIdResponse }
        ) => {
          console.log("getPOCQuestionById");

          state.isEnd = payload.Questions?.IsEnd;
          state.currentQuestion = payload.Questions;
          state.currentQuestionId = payload.Questions?.Id;
          state.questionsOptions = payload?.QuestionsOptions || [];
          state.nextQuestionId = payload?.Questions.NextQuestionId;
          state.prevencherPOCMessages = [
            ...state.prevencherPOCMessages,
            {
              messageId: payload.Questions.Id.toString(),
              id: prevencherId,
              date: new Date(),
              unread: false,
              message: payload.Questions.Desc,
              nextQuestionId: state.nextQuestionId,
            },
          ];
        }
      )
      .addMatcher(
        userApi.endpoints.getNextQuestionByQuestionnaireId.matchFulfilled,
        (
          state: IUserAuth,
          { payload }: { payload: TGetQuestionByIdResponse }
        ) => {
          state.currentQuestion = payload.Questions;
          state.currentQuestionId = payload.Questions.Id;
          state.questionsOptions = payload.QuestionsOptions || [];
          state.prevencherChatMessages = [
            ...state.prevencherChatMessages,
            {
              messageId: payload.Questions.Id.toString(),
              id: prevencherId,
              date: new Date(),
              unread: false,
              message: payload.Questions.Desc,
              nextQuestionId: state.nextQuestionId,
            },
          ];
          state.isEnd = payload.Questions.IsEnd;
        }
      )
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
        deleteCookie("refreshToken");
        deleteCookie("accessToken");
        state.user = initialUser;
        state.accessToken = "";
        state.refreshToken = "";
        state.error = action.payload.success ? null : action.payload.message;
        state.isAuth = false;
      })
      .addMatcher(userApi.endpoints.getUsers.matchRejected, storeErrors)
      .addMatcher(userApi.endpoints.updateUser.matchRejected, storeErrors)
      .addMatcher(userApi.endpoints.getUserByGuid.matchRejected, storeErrors)
      .addMatcher(
        userApi.endpoints.getMessageByUserId.matchFulfilled,
        (state: IUserAuth, { payload }: { payload: IUpdate[] }) => {
          state.user.updates = payload;
        }
      )
      .addMatcher(
        userApi.endpoints.GetCurrentQuestionnaireByUsersId.matchFulfilled,
        (state: IUserAuth, { payload }: { payload: TQuestionairResponse }) => {
          if (!payload.error) {
            state.prevencherChatMessages = [];
            if (payload.Questionnaire?.StartQuestion)
              state.startQuestion = payload.Questionnaire?.StartQuestion;
            state.currentQuestionnaireId = payload.Questionnaire?.Id ?? -1;
            state.currentQuestionnaireCompanies =
              payload.QuestionnaireCompanies;
            state.nextQuestionId = payload.Questions
              ? payload.Questions[0]?.Id
              : -1;
          }
        }
      )
      .addMatcher(
        userApi.endpoints.GetCurrentQuestionnaireByUsersId.matchRejected,
        storeErrors
      )
      .addMatcher(
        userApi.endpoints.getAppointmentsByUserId.matchFulfilled,
        (state: IUserAuth, { payload }: { payload: IAppointment[] }) => {
          state.user.appointments = payload;
        }
      )
      .addMatcher(
        userApi.endpoints.getExercisesByUserId.matchFulfilled,
        (state: IUserAuth, { payload }: { payload: TExercise[] }) => {
          state.user.exercises = payload;
        }
      )
      .addMatcher(
        userApi.endpoints.getQuestionnAirByGuidAndType.matchFulfilled,
        (state: IUserAuth, { payload }: { payload: TQuestionairResponse }) => {
          if (!payload.error) {
            state.currentQuestionnaireId = payload.Questionnaire?.Id ?? -1;
            state.currentQuestionnaireCompanies =
              payload?.QuestionnaireCompanies;
            state.nextQuestionId = payload?.Questions[0]?.Id;
            state.prevencherChatMessages = [];
          }
        }
      )
      .addMatcher(
        userApi.endpoints.getQuestionnAirByGuidAndType.matchPending,
        (state: IUserAuth) => {
          state.currentQuestionnaireId = -1;
          state.prevencherChatMessages = [];
        }
      ).addMatcher(
        userApi.endpoints.getAllCancelAppointmentsReasons.matchFulfilled,
        (state: IUserAuth, { payload }: { payload: TCancelAppointmentReason[] }) => {
          state.cancelAppointmentReasons = [...payload]
        }
      );
      ;
      
  },
});

export const { actions, reducer } = authUserSlice;
export default authUserSlice.reducer;
export const selectUser = (state: RootState) => state.user;

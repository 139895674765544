import styles from "./prevencherPage.module.css";
import PrevencherChatPOC from "../../components/prevencherChat/PrevencherChatPOC";
import { Link } from "react-router-dom";

function PocGuestsChatPage() {
  return (
    <section className={`${styles.section} ${styles.guests}`}>
      <h1 className={styles.guests_title}>פריוונצ’ר כאן בשבילך</h1>
      <Link to={"/signin/private"} className={styles.guests__link}>
        להירשם
      </Link>
      <PrevencherChatPOC />
    </section>
  );
}

export default PocGuestsChatPage;

import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_URL, BASE_URL_TEST } from "../../utils/constants";
import { getDefaultHeaders } from "./getDefaultHeaders";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query/react";
import { getCookie } from "../../utils/token";
import { IResponse } from "../types";
import { logoutSuccess, updateToken } from "../actions/auth";
import { IAuthResponse } from "../types/auth.types";

let BASE_URL_DYNAMIC = BASE_URL_TEST;

const loadConfig = async () => {
  try {
    const response = await fetch("/config.json");
    const config = await response.json();
    BASE_URL_DYNAMIC = config.serverUrl || BASE_URL_TEST;
  } catch (error) {
    console.error("Error loading config:", error);
  }
};

// This ensures that BASE_URL is loaded before you initialize your API
await loadConfig();

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL_DYNAMIC,
  prepareHeaders: (headers) => {
    const accessToken: string | undefined = getCookie("accessToken");
    return getDefaultHeaders(headers, accessToken);
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // const refreshToken = (api.getState() as RootState).user.refreshToken;
  const refreshToken = getCookie("refreshToken");
  let result = await baseQuery(args, api, extraOptions);
  if (
    refreshToken &&
    result.error &&
    (result.error.status === 401 ||
      (result.error.status === 403 &&
        (result.error.data as IResponse).message === "jwt expired"))
  ) {
    const refreshResult = await baseQuery(
      {
        url: `/token`,
        method: "POST",
        body: {
          token: refreshToken,
        },
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      api.dispatch(updateToken(refreshResult.data as IAuthResponse));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logoutSuccess());
    }
  }
  return result;
};

import prevencherPageStyles from "./prevencherPage.module.css";
import venchy from "../../images/questionnaire_doctor.png";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import { prevencherCategoris } from "../../utils/categories";
import { useEffect, useState } from "react";
import PrevencherChat from "../../components/prevencherChat/prevencherChat";
import { useLocation } from "react-router-dom";
import { Category, TLocationState } from "../../types/types";
import SideBarSmall from "../../components/sideBarSmall/sideBarSmall";
import PrevencherChatForGuests from "../../components/prevencherChat/prevencherChatForGuests";
import { getCookie } from "../../utils/token";
import PrevencherChatUrgent from "../../components/prevencherChat/prevencherChatUrgent";
import { useLazyGetQuestionnAirByGuidAndTypeQuery } from "../../services/api/user.api";
import { testUserEmail, testUserEmail2 } from "../../utils/constants";


interface IPrevencherPageProps {
  activeCategory: Category;
  changeCategory: (e: React.MouseEvent, cat: Category) => void;
}

function PrevencherPage({
  activeCategory,
  changeCategory,
}: IPrevencherPageProps) {
  const [category, setCategory] = useState("");
  const location = useLocation() as TLocationState;
  const token = getCookie("accessToken");
  const user = useAppSelector(userSelector);

  const isTestUser =
    user?.email.toLowerCase() === testUserEmail ||
    user?.email.toLowerCase() === testUserEmail2;


  const [getQuestionnAirOfGetHelpChat] = useLazyGetQuestionnAirByGuidAndTypeQuery();

  useEffect(() => {
    if (location.state?.pathname.includes("chatGetHelp")) {
      getQuestionnAirOfGetHelpChat(user.guid);
    }
    if (location.state?.pathname.includes("chat")) {
      setCategory("chat");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={prevencherPageStyles.section}>
      <div className={prevencherPageStyles.content}>
        {!category ? (
          <>
            <img
              src={venchy}
              className={prevencherPageStyles.venchy}
              alt={"תמונה של וונצ’י"}
            />
            <ul className={prevencherPageStyles.tabs}>
              {prevencherCategoris.map((cat, index) => (
                <li key={index} className={`${prevencherPageStyles.tab} ${(!isTestUser && cat.disabled) && prevencherPageStyles.tabBtn_disabled} hover`}>
                  <button
                    className={prevencherPageStyles.tabBtn}
                    onClick={() => (isTestUser || !cat.disabled) && setCategory(cat.value)}
                  >
                    {cat.title}
                  </button>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <ul className={prevencherPageStyles.tabs_horizontal}>
            {prevencherCategoris.map((cat, index) => (
              <li key={index} className={`${prevencherPageStyles.tab} ${(!isTestUser && cat.disabled) && prevencherPageStyles.tabBtn_disabled} hover`}>
                <button
                  className={`${prevencherPageStyles.tabBtn} ${prevencherPageStyles.tabBtn_horizontal
                    } ${cat.value === category && prevencherPageStyles.tabBtn_active
                    }`}
                  onClick={() => (!cat.disabled || isTestUser) && setCategory(cat.value)}
                >
                  <img
                    src={cat.img}
                    alt={cat.title}
                    className={prevencherPageStyles.btnImg}
                  />
                  {cat.title}
                </button>
              </li>
            ))}
          </ul>
        )}
        {category === "chat" &&
          (token === "guestToken" ? (
            <PrevencherChatForGuests />
          ) : location.state?.pathname.includes("chatGetHelp") ? (
            <PrevencherChatUrgent
              activeCategory={Category.Prevencher}
              changeCategory={changeCategory} />
          ) : (
            <PrevencherChat />
          ))}
      </div>
      <SideBarSmall />
    </div>
  );
}

export default PrevencherPage;

import styles from "./myAchievements.module.css";
import React from "react";
import achievementsImg from "../../images/achievements.png";

function MyAchievements() {
  return (
    <section className={styles.section}>
      <h2 className={styles.section__title}>מה עשיתי החודש</h2>
      <article className={styles.total}>
        <img
          src={achievementsImg}
          alt={"גיבור על טס לעבר המטרה שלו"}
          className={styles.total__img}
        />
        <div className={styles.total__content}>
          <h3 className={styles.total__number}>52</h3>
          <p className={styles.total__text}>פעולות התקדמות אל המטרות שלי!</p>
        </div>
      </article>
      <ul className={styles.achievements}>
        <li className={styles.achievementsLi}>
          <p className={styles.achievementsText}>צפיתי ב:</p>
          <p className={styles.achievementsNumber}>22</p>
          <p className={styles.achievementsText}>תרגילים</p>
        </li>
        <li className={styles.achievementsLi}>
          <p className={styles.achievementsText}>תרגלתי מיינדפולנס</p>
          <p className={styles.achievementsNumber}>30</p>
          <p className={styles.achievementsText}>פעמים</p>
        </li>
        <li className={styles.achievementsLi}>
          <p className={styles.achievementsText}>נפגשתי עם המטפל שלי</p>
          <p className={styles.achievementsNumber}>4</p>
          <p className={styles.achievementsText}>פגישות</p>
        </li>
        <li className={styles.achievementsLi}>
          <p className={styles.achievementsText}>השקעתי בהתפתחות שלי</p>
          <p className={styles.achievementsNumber}>49</p>
          <p className={styles.achievementsText}>שעות</p>
        </li>
      </ul>
    </section>
  );
}

export default MyAchievements;

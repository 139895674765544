import {
  IOption,
  IForumThread,
  IMessage,
  INotification,
  IReport,
  ISettings,
  ISubCategory,
  ITherapist,
  IVideo,
  TSendFileForm,
} from "../types/types";
import venchy from "../images/questionnaire_doctor.png";
import { IContactUsTheme } from "./categories";
import { IUserGoal } from "../services/types/goals.types";

export const phoneNumber = "*3596";
export const BASE_URL = "https://api.bpreven.com/";

export const BASE_URL_TEST = "https://api-test.bpreven.com/";

export const BASE_URL_LOCAL = "http://localhost:7241/";

export const testUserEmail = "admin@bpreven.com";
export const testUserEmail2 = "elena@bpreven.com";


export const mobileWidth = "(max-width: 600px)";
export const tabletWidth = "(max-width:1200px)";

export const noDataLabel = "אין מידע";

export const phonePrefixOptions = [
  "050",
  "051",
  "052",
  "053",
  "054",
  "055",
  "057",
  "058",
];

export const defaultAvatarLink = "https://private-user-images.githubusercontent.com/177111217/406731381-351992b7-8379-4cba-a029-451cf989a3a1.png?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJnaXRodWIuY29tIiwiYXVkIjoicmF3LmdpdGh1YnVzZXJjb250ZW50LmNvbSIsImtleSI6ImtleTUiLCJleHAiOjE3Mzc5MTMwMDcsIm5iZiI6MTczNzkxMjcwNywicGF0aCI6Ii8xNzcxMTEyMTcvNDA2NzMxMzgxLTM1MTk5MmI3LTgzNzktNGNiYS1hMDI5LTQ1MWNmOTg5YTNhMS5wbmc_WC1BbXotQWxnb3JpdGhtPUFXUzQtSE1BQy1TSEEyNTYmWC1BbXotQ3JlZGVudGlhbD1BS0lBVkNPRFlMU0E1M1BRSzRaQSUyRjIwMjUwMTI2JTJGdXMtZWFzdC0xJTJGczMlMkZhd3M0X3JlcXVlc3QmWC1BbXotRGF0ZT0yMDI1MDEyNlQxNzMxNDdaJlgtQW16LUV4cGlyZXM9MzAwJlgtQW16LVNpZ25hdHVyZT1mODQzOWZlNDc0OTJiMmVmYTZiMWIyNjU1NjlhZThlZGFjMzY4YmFjNDAzNTliYzkwYWFmOTEzNzBlYmZjNzdiJlgtQW16LVNpZ25lZEhlYWRlcnM9aG9zdCJ9.q6RjG1jdI-QkTa0NkzxL37hdbawliGYOkNIB_Jv8hDM"

export const customerService = "customerService";
export const israeliMobileRegExp = /^(05\d|9725\d)([-]?)\d{7}$/;
export enum appointmentOptions {
  Cancel = "cancel",
  Update = "update",
  Create = "create",
}

export const defaultSettings: ISettings = {
  sendToEmail: true,
  sendAds: true,
  sendNotifications: true,
};

export const cardInitialState = {
  cardNumber: "",
  cardUser: "",
  cvv: null,
  exp: "",
};
export const minCardNumberLength = 12;
export const cvvLength = 3;

export const charMax = 400;

export const questions: IContactUsTheme[] = [
  {
    title: "האם הפסיכולוגים שלכם מוסמכים?",
    text: "גישה רק לדברים החיוניים ביותר. שדרג לפרימיום וקבל שירותי תמיכה נפשית על ידי פסיכולוג מוסמך זמין 24/7, התייעצות בפורום עם מתמודדים כמוך, ועודגישה רק לדברים גישה רק לדברים החיוניים ביותר. שדרג לפרימיום וקבל שירותי תמיכה נפשית על ידי פסיכולוג מוסמך ",
  },
  {
    title: "האם הפסיכולוגים שלכם מוסמכים?",
    text: "שלכם שלכם שלכם שלכםשלכםשלכם שלכם שלכם שלכם",
  },
  {
    title: "האם הפסיכולוגים שלכם מוסמכים?",
    text: "שלכם שלכם שלכם שלכםשלכםשלכם שלכם שלכם שלכם",
  },
];

export const messages: IMessage[] = [
  {
    messageId: "1",
    id: "123456",
    date: new Date(),
    message: "מה שלומך?",
    unread: true,
  },
];

export const week = [
  "יום א'",
  "יום ב'",
  "יום ג'",
  "יום ד'",
  "יום ה'",
  "יום ו'",
  "יום ש'",
];

export const videoExample: IVideo[] = [
  {
    link: "https://media.istockphoto.com/id/641530166/video/young-woman-exercising-yoga-by-the-lake-at-sunset.mp4?s=mp4-640x640-is&k=20&c=drbKOmtsa1zx_fAsqLKe2GBI96aqbmnGVwU6rR2lzsw=",
    title: "תרגול מיינדפולנס",
    about:
      "׳קשיבות׳ הוא שמה העברי של שיטת תרגול מדיטטיבי הכרוכה בהפניית הקשב באופן מכוון ובלתי שיפוטי לחוויות ותופעות המתרחשות ברגע. הקשיבות מתוארת כמודעות מכבדת ונטולת שיפוט ׳קשיבות׳ הוא שמה העברי של שיטת תרגול מדיטטיבי הכרוכה בהפניית הקשב באופן מכוון ובלתי שיפוטי לחוויות ותופעות המתרחשות ברגע. הקשיבות מתוארת כמודעות מכבדת ונטולת שיפוט",
  },
  {
    link: "https://media.istockphoto.com/id/1192719321/video/calm-young-african-man-meditate-on-sofa-with-eyes-closed.mp4?s=mp4-640x640-is&k=20&c=uV8ZVhrWA_yrurfcMILSQx17M1pU1j_59PqMRkzSVy8=",
    title: "תרגול מיינדפולנס",
    about: "תרגול מיינדפולנס קצר לשיפור הקשב והריכוז והפחתת חרדה",
  },
  {
    link: "https://media.istockphoto.com/id/1192719321/video/calm-young-african-man-meditate-on-sofa-with-eyes-closed.mp4?s=mp4-640x640-is&k=20&c=uV8ZVhrWA_yrurfcMILSQx17M1pU1j_59PqMRkzSVy8=",
    title: "מיינדפולנס",
    about:
      "מדיטציית מיינדפולנס זוהי מדיטציה המפתחת תשומת לב, ריכוז, קשיבות ומודעות, שמביאה לשינוי באופן שבו אנו חווים את החיים ומלמדת אותנו יכולות התמודדות עם קשיים שהחיים מזמנים, ביניהם התקופה לפני הניתוח וההחלמה לאחר מכן",
  },
];

export const feelVariants = [
  "נפלא, מלא באנרגיה!",
  "מצוין",
  "טוב מאוד",
  "טוב",
  "בסדר",
  "תקופה מאתגרת",
  "לא קל עכשיו",
  "קשה לי",
  "בלתי נסבל",
  "אני חייב עזרה",
];
export const goalsVariants = [
  "ללכת למכון הכושר שלוש פעמים בשבוע",
  "להוריד במינון התרופות",
  "לישון שינה רציפה בלילה",
  "לבצע את כל התרגילים המומלצים לי",
];
export const getHelpVariants = [
  "אני צריך לדבר עם מישהו דחוף",
  "אני צריך מרשם חדש",
  "אני רוצה א",
  "אני רוצה ב",
  "אני רוצה ג",
];

export let initialGoalsVariants: IUserGoal[] = [];

export const prevencherMessages: IMessage[] = [
  {
    messageId: "1",
    id: "1",
    message: "אני תמיד מכשיב לך, איך אתה מרגיש, מ 1 עד 10?",
    date: new Date(),
    unread: false,
  },
];
export const prevencherMessagesSetGoals: IMessage[] = [
  {
    messageId: "1",
    id: "1",
    message:
      "בוא נקבע לך מספר מטרות ונבדוק איך אתה מתקדם איתן לאורך זמן, בסדר? בחר מתוך הרשימה מטרה שנראית לך ברת השגה",
    date: new Date(),
    unread: false,
  },
];
export const prevencherMessagesNewUser: IMessage[] = [
  {
    messageId: "1",
    id: "1",
    message: "הי נתנאל, ברכות על הצטרפותך! יש לי כמה שאלות לפני שנתחיל. מוכן?",
    date: new Date(),
    unread: false,
  },
];
export const prevencherMessagesGetHelp: IMessage[] = [
  {
    messageId: "1",
    id: "1",
    message: "הי נתנאל, על מה תרצה לדבר היום? אנא בחר את הנושא",
    date: new Date(),
    unread: false,
  },
];

export const venchyData: Partial<ITherapist> = {
  id: "venchy",
  name: "וונצ’י",
  avatar: venchy,
};
export const yesNoVariants: string[] = ["כן", "לא"];

export const auxiliaryWords = ["אני", "לא", "יכול", ",", " ", "", "רוצה"];

export enum ContactUsSearchOptions {
  Input = "input",
  List = "list",
}

const textExample: string =
  "על מנת לשדרג מנוי, יש להיכנס לאזור “הפרופיל שלי” - “הגדרות” - “תכנית המנוי שלנו” ושם לבחור במנוי הרצוי. " +
  "יש המון רעש בחוץ, בסביבה הקרובה והרחוקה שלנו, אבל הרעש המשמעותי ביותר מגיע מתוכנו דרך המחשבות שלנו. " +
  "למעשה, האויב הגדול ביותר שלנו לא נמצא בחוץ אלא בפנים והוא משפיע על איך אנחנו חווים את המציאות בחוץ." +
  " על מנת לשדרג מנוי, יש להיכנס לאזור “הפרופיל שלי” - “הגדרות” - “תכנית המנוי שלנו” ושם לבחור במנוי הרצוי. " +
  "יש המון רעש בחוץ, בסביבה הקרובה והרחוקה שלנו, אבל הרעש המשמעותי ביותר מגיע מתוכנו דרך המחשבות שלנו. " +
  "למעשה, האויב הגדול ביותר שלנו לא נמצא בחוץ אלא בפנים והוא משפיע על איך אנחנו חווים את המציאות בחוץ." +
  " יש המון רעש בחוץ, בסביבה הקרובה והרחוקה שלנו, אבל הרעש המשמעותי ביותר מגיע מתוכנו דרך המחשבות שלנו." +
  " למעשה, האויב הגדול ביותר שלנו לא נמצא בחוץ אלא בפנים והוא משפיע על איך אנחנו חווים את המציאות בחוץ.";
const textExample2: string =
  "לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית סחטיר בלובק. תצטנפל בלינדו למרקל אס לכימפו, דול, צוט ומעיוט - לפתיעם ברשג - ולתיעם גדדיש. קוויז דומור ליאמום בלינך רוגצה. לפמעט מוסן מנת.";
export const contactUsList: IContactUsTheme[] = [
  {
    title: "שדרוג מנוי",
    text: textExample,
  },
  {
    title: "סוגי מנויים",
    text: textExample2,
  },
  {
    title: "מידע כללי",
    text: textExample2,
  },
  {
    title: "הרשמה",
    text: textExample,
  },
  {
    title: "קבע פגישה עם מומחה",
    text: textExample2,
  },
  {
    title: "מרשמים ומסמכים",
    text: textExample2,
  },
];

export const notificationsExample: INotification[] = [
  { unread: true, text: "פניה חדשה מד״ר נאמן", section: "chat" },
  { unread: true, text: "הופקה עבורך חשבונית חדשה", section: "profile" },
  { unread: true, text: "תגובה לשרשור שפתחת בפורום", section: "chat" },
  { unread: false, text: "פניה חדשה מנחום, המדריך", section: "chat" },
  { unread: false, text: "התקבל מרשם חדש", section: "treatment" },
];

export const forumThemes = [
  "דיכאון וחרדה",
  "הפרעות שינה",
  "תרופות",
  "מצב רו",
  "הפרעות אישיות",
];

export const forumExample: {
  id: string;
  title: string;
  avatar: string;
  userName: string;
  date: Date;
  lastMessage: string;
  messages: number;
}[] = [
  {
    id: "123",
    title: "בדיחות על דיכאון, שמחה וששון",
    avatar:
      "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8cGVvcGxlfGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60",
    userName: "הצועד בנעליו",
    date: new Date(),
    lastMessage:
      "בואו נעלה בדיחות על דיכאון, אני ראשון: כומר ורבי נפגשים ברבנות בפתח תקווה ",
    messages: 10,
  },
  {
    id: "345",
    title: "מה עושים כשהמחשבות השחורות עולות לפתע?",
    avatar:
      "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60",
    userName: "אחד מהקהל",
    date: new Date(),
    lastMessage:
      "מה עושים כשהמחשבות השחורות עולות לפתע? למעשה, האויב הגדול ביותר שלנו לא נמצא בחוץ אלא בפנים והוא משפיע על איך אנחנו חווים את המציאות בחוץ.",
    messages: 20,
  },
  {
    id: "12389749326",
    title: "בדיחות על דיכאון, שמחה וששון",
    avatar:
      "https://images.unsplash.com/photo-1495216875107-c6c043eb703f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60",
    userName: "הצועד בנעליו",
    date: new Date(),
    lastMessage:
      "מה עושים כשהמחשבות השחורות עולות לפתע? בואו נעלה בדיחות על דיכאון, אני ראשון: כומר ורבי נפגשים ברבנות בפתח תקווה ",
    messages: 4,
  },
];

export const forumThreadExample: IForumThread = {
  id: "1",
  title: "מה עושים כשהמחשבות השחורות עולות לפתע?",
  messages: [
    {
      id: "2",
      user: {
        userName: "אחד מהקהל",
        avatar:
          "https://images.unsplash.com/photo-1495216875107-c6c043eb703f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60",
        date: new Date(),
      },
      message:
        "יש המון רעש בחוץ, בסביבה הקרובה והרחוקה שלנו, אבל הרעש המשמעותי ביותר מגיע מתוכנו דרך המחשבות שלנו. למעשה, האויב הגדול ביותר שלנו לא נמצא בחוץ אלא בפנים והוא משפיע על איך אנחנו חווים את המציאות בחוץ." +
        "זו הבעיה הגדולה של כולנו, וכל כך הרבה שיטות ומומחים ביקשו לפתור אותה, אך עד היום לא נמצא לה, לבעיה שמטרידה את האנושות שולה - פתרון.",
    },
    {
      id: "3",
      user: {
        userName: "גל ברקוביץ",
        avatar:
          "https://media.istockphoto.com/id/1386479313/photo/happy-millennial-afro-american-business-woman-posing-isolated-on-white.webp?b=1&s=170667a&w=0&k=20&c=ahypUC_KTc95VOsBkzLFZiCQ0VJwewfrSV43BOrLETM=",
        date: new Date(),
        tag: "מדריך",
      },
      message:
        "הקושי לשלוט במחשבות הוא גדול, אין בכך ספק." +
        "אנחנו לא יכולים לשלוט בהם אבל אנחנו בהחלט יכולים להגיב בצורה חיובית גם למחשבות שליליות.",
    },
    {
      id: "4",
      user: {
        userName: "יובלי",
        avatar:
          "https://media.istockphoto.com/id/1368424494/photo/studio-portrait-of-a-cheerful-woman.jpg?s=612x612&w=0&k=20&c=ISNDV3ZXeNU6VvDhR7KXFd6y0saq4Eji15cep_Gj8Eg=",
        date: new Date(),
      },
      message:
        "אבל גל, ככל שהמחשבות מתחזקות ככה היכולת שלנו להמשיך ולהגיב בצורה חיובית קטנה... אני מרגישה בלופ שלא נגמר",
      replyTo: "3",
    },
  ],
};

export const myReportsSummaryExample: IReport[] = [
  {
    id: "1",
    title: "סיכום פגישה",
    subTitle: "אפריל 2023",
    date: new Date(),
    content: "",
  },
  {
    id: "2",
    title: "סיכום פגישה",
    subTitle: "ספט 22 - מרץ 23",
    date: new Date(),
    content: "",
  },
  {
    id: "3",
    title: "סיכום פגישה",
    subTitle: "מרץ 2023",
    date: new Date(),
    content: "",
  },
];
export const myReportsPeriodExample: IReport[] = [
  {
    id: "1",
    title: "דו״ח התקדמות",
    subTitle: "אפריל 2023",
    date: new Date(),
    content: "",
  },
  {
    id: "2",
    title: "דו״ח התקדמות",
    subTitle: "ספט 22 - מרץ 23",
    date: new Date(),
    content: "",
  },
];

export const filterOptions: ISubCategory[] = [
  { value: "week", title: "השבוע" },
  { value: "lastWeek", title: "שבוע שעבר" },
  { value: "month", title: "החודש" },
  { value: "lastMonth", title: "חודש שעבר" },
  { value: "date", title: "תאריך מסוים" },
];

export const newReportSubjects: ISubCategory[] = [
  { value: "1", title: "נושא אחד" },
  { value: "2", title: "נושא שני" },
  { value: "3", title: "נושא שלישי" },
  { value: "4", title: "נושא רביעי" },
  { value: "5", title: "נושא חמישי" },
];

export const forumCategories: ISubCategory[] = [
  { value: "depression", title: "דיכאון" },
  { value: "anxieties", title: "חרדות" },
  { value: "personalityDisorders", title: "הפרעות אישיות" },
  { value: "sleepDisorders", title: "הפרעות שינה" },
  { value: "moodDisorders", title: "הפרעות מצב רוח" },
  { value: "motivation", title: "מוטיבציה" },
  { value: "schizophrenia", title: "סכיזופרניה" },
  { value: "generalDistress", title: "מצוקה כללית" },
  { value: "other", title: "אחר" },
];

export const genderOptions: { id: number; name: string }[] = [
  {
    id: 1,
    name: "זכר",
  },
  {
    id: 2,
    name: "נקבה",
  },
  {
    id: 3,
    name: "לא מוגדר",
  },
  {
    id: 4,
    name: "אחר",
  },
];
export const countryOptions: string[] = ["ישראל", "ארצות הברית", "קנדה"];

export const allUsersFlows = [
  {
    id: 1,
    symbol: "U1",
    desc: "משתמש אורח",
    statusId: 1,
  },
  {
    id: 3,
    symbol: "U2",
    desc: "משתמש רשום",
    statusId: 1,
  },
];
export const existingUserFlowId = 3;
export const guestUserFlowId = 1;

export const docsCategories: ISubCategory[] = [
  { value: "", title: "בחר נושא מסמך:" },
  { value: "diagnoses", title: "אבחונים" },
  { value: "prescriptions", title: "מרשמים" },
  { value: "applications", title: "הפניות" },
  { value: "summaries", title: "סיכומי פגישות טיפול" },
  { value: "other", title: "אחר" },
];
export const applicationDetails: ISubCategory[] = [
  { value: "", title: "בחר פרטי בקשה:" },
  { value: "newPrescription", title: "הנפקת מרשם חדש" },
  { value: "newApplication", title: "הפניה חדשה" },
  { value: "other", title: "אחר" },
];
export const urgencyVariants: ISubCategory[] = [
  { value: "", title: "בחר דחיפות:" },
  { value: "1", title: "נמוך" },
  { value: "2", title: "בינוני" },
  { value: "3", title: "גבוה" },
];

export const sendFileFormInitialValue: TSendFileForm = {
  category: docsCategories[0],
  details: applicationDetails[0],
  urgent: urgencyVariants[0],
  file: null,
};

export const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes
export const WARNING_TIMEOUT = 14 * 60 * 1000; // 14 minutes
// export const INACTIVITY_TIMEOUT = 2 * 60 * 1000; // for test
// export const WARNING_TIMEOUT = 1 * 60 * 1000; // for test

export const WARNING_DURATION = 60 * 1000;
export const formatCountdown = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds
  ).padStart(2, "0")}`;
};

export const TYPING_CODE = "TYPING_CODE";
export const END_CALL_CODE = "END_CALL_CODE";
export const END_APPOINTMENT = "END_APPOINTMENT";

export const chatNoteTypes = {
  isTyping: "isTyping",
  text: "text",
} as const;


export const cancelAppointmentResaonsLocal: IOption[] = [
  {value: "medical", label: "סיבה רפואית"},
  {value: "technica;", label: "סיבה טכנית"},
  {value: "familyEvent", label: "אירוע משפחתי"},
  {value: "personal", label: "סיבה אישית"},
  {value: "stopTreatment", label: "הפסקת טיפול"},
  {value: "other", label: "אחר", isCustom: true},
]

import { createSlice } from "@reduxjs/toolkit";
import { IGenderOption } from "../../types/types";
import {
  allUsersFlows,
  existingUserFlowId,
  guestUserFlowId,
} from "../../utils/constants";
import { utilsApi } from "../api/utils.api";

type TUserFlow = {
  id: number;
  symbol: string;
  desc: string;
  statusId: number;
};
type TUtilsState = {
  allUserFlows: TUserFlow[];
  allGenders: IGenderOption[];
  existingUserFlowId: number;
  guestUserFlowId: number;
};
const initialState: TUtilsState = {
  allUserFlows: allUsersFlows,
  allGenders: [],
  existingUserFlowId: existingUserFlowId,
  guestUserFlowId: guestUserFlowId,
};

export const utilsSlice = createSlice({
  name: "utils",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      utilsApi.endpoints.getAllGenders.matchFulfilled,
      (state: TUtilsState, { payload }) => {
        state.allGenders = payload;
        console.log(payload);
      }
    );
  },
});

export const { actions, reducer } = utilsSlice;
export default utilsSlice.reducer;
